import React from 'react';
import lgs from '../../Assets/Clients/exp.png';
import { LuCheckSquare } from 'react-icons/lu';

const ExperienceCard = () => {
    const works = [
        'Superior Quality Concrete',
        'On-Time Deliveries',
        'Expert Support',
        'Modern Solutions',
        'Competitive Pricing',
        'Sustainable Practices'
    ];

    return (
        <React.Fragment>
            <div className="flex gap-8 flex-col md:flex-row w-full justify-center items-center bg-[#f7fffe] py-8 px-8 md:px-10 text-white">
                <div className="md:w-1/2 w-full">
                    <img
                        className="md:w-[90%] w-full h-auto rounded-lg"
                        src={lgs}
                        alt="luggage-pic"
                    />
                </div>

                <div className="flex w-full md:w-1/2 md:mt-0">
                    <div className="">
                        <div style={{ fontFamily: 'cursive' }} className='text-primaryColor text-xl w-full tracking-wide'>
                            About Us
                        </div>

                        <div className='text-primaryTextColor text-4xl font-bold w-full mt-2 tracking-wide leading-normal'>
                            Reliable and Dedicated Concrete Provider for Your Construction.
                        </div>

                        <div className='text-secondaryTextColor mr-2 mt-4 w-full tracking-wide text-justify'>
                            At Singh RMC, we pride ourselves on being a reliable and dedicated provider of high-quality concrete for all your construction needs. Our commitment to excellence ensures that you receive the best materials, delivered on time, every time. Trust us to support your projects with superior concrete solutions, tailored to meet the demands of modern construction.
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                            {works?.map((data, i) => (
                                <div key={i} className="flex items-center gap-2 text-primaryColor text-xl font-bold w-full tracking-wide">
                                    <LuCheckSquare size={22} />
                                    <span>{data}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ExperienceCard
