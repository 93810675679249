import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { ImShocked } from "react-icons/im";

const LogoutModal = ({ show, onClose }) => {
  const modalAnimationProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };
  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.removeItem("User");
    localStorage.removeItem("LoginToken");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("SinghRMCAdmin");
    navigate("/logout");
  };

  return (
    <>
      {show && (
        <motion.div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50"
          {...modalAnimationProps}
        >
          <motion.div
            className="bg-white p-6 rounded-md shadow-md"
            initial={{ y: "-100vh" }}
            animate={{ y: 0 }}
            exit={{ y: "-100vh" }}
          >
            <div className="flex justify-center items-center mb-3">
              <ImShocked size={50} color="red" />
            </div>

            <h2 className="text-lg font-semibold mb-4 text-black">
              Are you sure you want to logout?
            </h2>

            <div className="flex justify-end">
              <button
                className="bg-slate-400 hover:bg-slate-600 tracking-wider text-white px-4 py-2 rounded-md mr-2 transition-all duration-200 cursor-pointer"
                onClick={onClose}
              >
                No
              </button>

              <button
                className="bg-primaryColor hover:bg-primaryHoverColor tracking-wider text-white px-4 py-2 rounded-md transition-all duration-200 cursor-pointer"
                onClick={handleLogOut}
              >
                Yes
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </>
  );
};

export default LogoutModal;
