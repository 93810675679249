import React from "react";
import { IoCloseCircle } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
  updateModalOpen,
  saveCurrentProductDetails,
  modalSet,
  editServiceModal,
} from "../redux/slice/productSlice";
import { useForm } from "react-hook-form";
import axios from "axios";
import { check } from "../redux/slice/productSlice";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useState } from "react";
import { updateCheck } from "../redux/slice/productSlice";

const EditService = () => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [currentProduct, setCurrentProduct] = useState("");
  const [editItemIndex, setEditItemIndex] = useState("");
  const [load, setLoad] = useState(false);

  const currentProductData = useSelector((state) => {
    return state.products.currentProductDetails;
  });

  const check2 = useSelector((state) => {
    return state.products.check2;
  });
  const productId = useSelector((state) => {
    return state.products.id;
  });
  console.log(productId);
  const handleServicesName = (value) => {
    dispatch(
      saveCurrentProductDetails((prev) => {
        return { ...prev, servicesName: value };
      })
    );
  };
  const handleDescription = (value) => {
    dispatch(
      saveCurrentProductDetails((prev) => {
        return { ...prev, description: value };
      })
    );
  };
  const handleServiceDetails = (value) => {
    dispatch(
      saveCurrentProductDetails((prev) => {
        return { ...prev, servicesDetails: value };
      })
    );
  };

  const cloudName = "dnw1ttr02";
  const upload_preset = "wjs1zyo5";
  const updateProduct = (Data) => {
    setLoad(true);

    const imgObj = [Data.file1[0], Data.file2[0], Data.file3[0]];
    console.log(imgObj);

    const checkImg = imgObj.filter((eachImg) => {
      return eachImg != undefined;
    });

    console.log("checking", checkImg);

    if (checkImg.length > 0) {
      let newArr = [];
      checkImg.forEach((image) => {
        const file = image;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", upload_preset);

        axios
          .post(
            `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
            formData
          )
          .then((response) => {
            console.log(response.data);
            newArr.push(response.data.secure_url);
            if (newArr.length == checkImg.length) {
              let productData = {
                ...Data,
                ImgUrls: newArr,
              };

              console.log(productData);
              let config = {
                method: "put",
                maxBodyLength: Infinity,
                url: `https://singh-rmc.onrender.com/pgc-api/service/updateservice/${productId}`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: productData,
              };

              axios
                .request(config)
                .then((response) => {
                  console.log(response.data);
                  toast.success(response.data.massage);
                  setLoad(false);
                  dispatch(editServiceModal(false));
                  if (check2 == false) {
                    dispatch(updateCheck(true));
                  } else {
                    dispatch(updateCheck(false));
                  }
                  return;
                })
                .catch((error) => {
                  console.log(error);
                  toast.error(error.response.data.massage);
                  setLoad(false);
                  dispatch(editServiceModal(false));
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } else {
      console.log("***********************");
      let productData;

      productData = {
        ...Data,
      };

      // if images are not present
      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `https://singh-rmc.onrender.com/pgc-api/service/updateservice/${productId}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: productData,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);

          toast.success(response.data.massage);
          dispatch(check(response.data.Product));
          setLoad(false);
          dispatch(editServiceModal(false));
          if (check2 == false) {
            dispatch(updateCheck(true));
          } else {
            dispatch(updateCheck(false));
          }
          return;
        })
        .catch((error) => {
          console.log(error);
          dispatch(editServiceModal(false));
          toast.error(error.response.data.massage);
          setLoad(false);
        });
    }
  };

  return (
    <div className="h-[100vh] w-[100%] flex justify-center items-center z-50 bg-[#0f02025b] absolute top-0 ">
      <div
        id="careers"
        className="h-[35rem] w-[35rem] rounded-lg relative bg-white p-[1.5rem] overflow-y-scroll"
      >
        <IoCloseCircle
          size="25"
          color="black"
          onClick={() => {
            dispatch(editServiceModal(false));
          }}
          className="absolute top-2 right-2"
        />

        <h1 className="text-center font-medium text-[1.5rem] text-black mb-5">
          Update Service
        </h1>
        {load ? (
          <div className="h-[100%] w-[100%]  flex flex-col justify-center items-center">
            <div class="loader1"></div>
            <p className="my-1 text-center text-black">
              Updating your service. Kindly wait....
            </p>
          </div>
        ) : (
          <div className="">
            <form
              onSubmit={handleSubmit(updateProduct)}
              action=""
              className="text-black"
            >
              <label
                className="font-semibold text-[1rem]"
                htmlFor="servicesName"
              >
                Service Name
              </label>
              <br />
              <input
                className="p-3 border my-2 border-blue-500 h-[4rem] rounded-lg w-[100%]"
                type="text"
                name="servicesName"
                placeholder="Enter Your Product Name"
                {...register("servicesName")}
                onChange={(e) => {
                  handleServicesName(e.target.value);
                }}
                value={currentProductData.servicesName}
              />
              <br />
              <label
                className="font-semibold text-[1rem]"
                htmlFor="description"
              >
                Description
              </label>
              <br />
              <textarea
                className=" p-3 border my-2 border-blue-500 h-[8rem] rounded-lg w-[100%]"
                type="text"
                name="description"
                placeholder="Write a description her"
                {...register("description")}
                onChange={(e) => {
                  handleDescription(e.target.value);
                }}
                value={currentProductData.description}
              />
              <br />
              <label
                className="font-semibold text-[1rem]"
                htmlFor="servicesDetails"
              >
                Service Details
              </label>
              <br />
              <input
                className="p-3 border my-2 border-blue-500 h-[4rem] rounded-lg w-[100%]"
                type="text"
                name="servicesDetails"
                placeholder="Write minimum 1 key benifit here."
                {...register("servicesDetails")}
                onChange={(e) => {
                  handleServiceDetails(e.target.value);
                }}
                value={currentProductData.servicesDetails}
              />
              <br />
              {/* img uPLOPAD SECTION */}
              <div className=" flex md:flex-row xs:flex-col">
                <div className="md:w-[50%] xs:w-[100%] mr-3">
                  <label className="font-medium" htmlFor="file1">
                    Image 1
                  </label>
                  <input
                    name="file1"
                    type="file"
                    {...register("file1")}
                    placeholder="Upload Your Image here."
                    className="my-2 p-3 text border h-[3rem] border-blue-500 rounded-lg w-[100%]"
                  />
                </div>
                <div className="md:w-[50%] xs:w-[100%]">
                  <label className="font-medium" htmlFor="file2">
                    Image 2
                  </label>
                  <input
                    name="file2"
                    type="file"
                    {...register("file2")}
                    placeholder="Upload Your Image here."
                    className="my-2 p-3 text border h-[3rem] border-blue-500 rounded-lg w-[100%]"
                  />
                </div>
              </div>

              {/* section 6 img upload */}

              <div className="md:w-[50%] xs:w-[100%] mr-3">
                <label className="font-medium" htmlFor="file5">
                  Image 3
                </label>
                <input
                  type="file"
                  name="file3"
                  {...register("file3")}
                  placeholder="Upload Your Image here."
                  className="my-2 p-3 text border h-[3rem] border-blue-500 rounded-lg w-[100%]"
                />
              </div>

              <button className="h-[2.5rem] w-[6.5rem] rounded-lg bg-[#1f2544] text-white font-semibold">
                Update
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditService;
