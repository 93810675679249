import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import { RiWhatsappFill } from "react-icons/ri";
import { BiSolidPhoneCall, BiSolidUserCircle } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import logo from "../Assets/Logos/rmcLogo.png";
import hamburger from "../Assets/Clients/menu.png";
import LogoutModal from "../Components/UserProfile/modals/LogoutModal";
import { useEffect } from "react";
import axios from "axios";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { GrInstagram } from "react-icons/gr";

const Navbar = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [productData, setProductData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [check, setCheck] = useState(false);
  const [check2, setCheck2] = useState(false);

  const [mydropdown, setMyDropdown] = useState(false);
  console.log(productData);
  const navigate = useNavigate();
  const Path = useLocation();

  const closeLogoutModal = () => {
    setShowLogoutModal(false);
  };

  const animationProps = {
    initial: { y: "-100%", opacity: 0 },
    animate: { y: 0, opacity: 1, transform: "translate3d(0,0,0)" },
    exit: { y: "-100%", opacity: 0, transition: { delay: 0.2 } },
    transition: { duration: 0.3, ease: "easeInOut" },
  };

  const drawerNavItems = [
    { label: "Home", path: "/" },
    { label: "About Us", path: "/about-us" },
    { label: "Contact Us", path: "/contact-us" },
    { label: "Career", path: "/career" },
    { label: "Gallery", path: "/gallery" },
  ];

  const navItems = [
    { label: "Home", path: "/" },
    { label: "Services", dropdown: true },
    { label: "Products", dropdown: true },
    { label: "About Us", path: "/about-us" },
    { label: "Contact Us", path: "/contact-us" },
    { label: "Career", path: "/career" },
    { label: "Gallery", path: "/gallery" },
  ];

  const getAllproducts = () => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://singh-rmc.onrender.com/pgc-api/getallproducts/getProducts`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data.product);
        setProductData(response.data.product);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllServices = () => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://singh-rmc.onrender.com/pgc-api/service/getallservice`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data.allServices);
        setServiceData(response.data.allServices);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const navigateToProductDetails = (id) => {
    navigate(`/product/details/${id}`);
  };
  const navigateToServiceDetails = (id) => {
    navigate(`/service/details/${id}`);
  };

  useEffect(() => {
    getAllproducts();
    getAllServices();
  }, []);

  return (
    <React.Fragment>
      <div className="bg-[#ffffff] md:flex hidden items-center justify-between px-10 py-2">
        <LogoutModal show={showLogoutModal} onClose={closeLogoutModal} />

        <div className="flex gap-6 font-bold">
          <p className="flex items-center gap-2 text-[14px] tracking-wider text-[#333333]">
            <RiWhatsappFill size={20} color="#25D366" /> +91-9776170355
          </p>
          <p className="flex items-center gap-2 text-[14px] tracking-wider text-[#333333]">
            <BiSolidPhoneCall size={20} color="#1E1E1E" /> +91-9437710574
          </p>
        </div>

        <div className="flex gap-3 font-bold items-center text-[14px]  tracking-wider text-[#333333]">
          {/* Singh House, Nimeishapur, Bhagatpur, Cuttack */}
          <a
            href=" https://www.facebook.com/profile.php?id=61563921037291"
            target="_blank"
          >
            <FaFacebook size={20} color="#0866ff" />
          </a>
          {/* <a href="https://www.m/" target="_blank">
            <GrInstagram size={20} color="#fe0269" />
          </a> */}
          <a href="https://x.com/singhRMC_Infra" target="_blank">
            <FaTwitter size={20} color="#1da1f2" />
          </a>
          <a
            href="https://www.youtube.com/@aSinghRmcInfraPvtLtdReadyMixco"
            target="_blank"
          >
            <FaYoutube size={20} color="#ff0000" />
          </a>
          <a
            href=" https://www.instagram.com/singhrmcinfra?igsh=YzljYTk1ODg3Zg=="
            target="_blank"
          >
            <GrInstagram size={20} color="#e6407b" />
          </a>
        </div>
      </div>

      <div className="bg-[#1E1E1E] shadow-lg py-2 px-10 ">
        <div className="m-auto flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={logo}
              alt="Logo"
              className="w-[8.9rem] xl:w-[9.3rem] h-auto"
            />
          </div>

          <div className="hidden lg:flex items-center space-x-8 tracking-wider text-[#888888]">
            {navItems?.map((item, index) =>
              item.dropdown ? (
                <div>
                  {item.label == "Services" ? (
                    <div
                      key={index}
                      className="cursor-pointer group relative inline-block hover:text-primaryColor duration-300 tracking-wide"
                    >
                      <div className="flex items-center text-[1.1rem]">
                        <p>{item.label}</p>

                        <RiArrowDropDownLine color="white" size="25" />
                      </div>
                      <div className="absolute z-10 top-full left-0 bg-white flex flex-col w-[200px] rounded-b-md opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-all duration-300 transform origin-top scale-y-0 group-hover:scale-y-100">
                        {serviceData.map((each) => {
                          return (
                            <div
                              onClick={() => {
                                navigateToServiceDetails(each._id);
                              }}
                              className="cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300"
                            >
                              {each.servicesName}
                            </div>
                          );
                        })}

                        {/* <div
                          // onClick={() => navigate("/services/male_services")}
                          className="cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300"
                        >
                          Ready Mix Concrete
                        </div>

                        <div
                          // onClick={() => navigate("/services/female_services")}
                          className="text-themeBlack cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300"
                        >
                          PCQ & DLC
                        </div>

                        <div
                          // onClick={() => navigate("/services/male_services")}
                          className="cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300"
                        >
                          RMC Equipment on Rent
                        </div>

                        <div
                          // onClick={() => navigate("/services/female_services")}
                          className="text-themeBlack cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300"
                        >
                          Real Estate
                        </div> */}
                      </div>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className=" cursor-pointer group relative inline-block hover:text-primaryColor duration-300 tracking-wide"
                    >
                      <div className="flex items-center text-[1.1rem]">
                        <p>{item.label}</p>

                        <RiArrowDropDownLine color="white" size="25" />
                      </div>
                      <div className="absolute z-10 top-full left-0 bg-white flex flex-col w-[200px] rounded-b-md opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-all duration-300 transform origin-top scale-y-0 group-hover:scale-y-100">
                        {productData?.map((each) => {
                          return (
                            <div
                              onClick={() => {
                                navigateToProductDetails(each._id);
                              }}
                              className="cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300"
                            >
                              {each?.productName}
                            </div>
                          );
                        })}

                        {/* <div
                          // onClick={() => navigate("/services/male_services")}
                          className="cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300"
                        >
                          SSC
                        </div>

                        <div
                          // onClick={() => navigate("/services/female_services")}
                          className="text-themeBlack cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300"
                        >
                          HSC
                        </div>

                        <div
                          // onClick={() => navigate("/services/male_services")}
                          className="cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300"
                        >
                          FARC
                        </div>

                        <div
                          // onClick={() => navigate("/services/female_services")}
                          className="text-themeBlack cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300"
                        >
                          Real Estate
                        </div> */}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  key={index}
                  className={`${
                    Path.pathname === item.path
                      ? "text-primaryColor border-primaryColor"
                      : ""
                  } cursor-pointer nav-item text-[1.1rem]`}
                  onClick={() => navigate(item.path)}
                >
                  {item.label}
                </div>
              )
            )}
          </div>

          <div className="hidden lg:flex items-center gap-4">
            <BiSolidUserCircle
              className="cursor-pointer"
              onClick={() => {
                navigate("/login");
              }}
              size={50}
              color="#28B40E"
            />
            {/* <button
              onClick={() => navigate("/login")}
              className="h-10 w-20 rounded-md bg-primaryColor text-white"
            >
              Call Us
            </button> */}
          </div>

          <div
            onClick={() => setDrawerOpen(true)}
            className="text-center ml-2 lg:hidden flex"
          >
            <motion.img
              animate={{
                scale: [1, 2, 2, 1, 1],
                rotate: [0, 0, 270, 270, 0],
                borderRadius: ["20%", "20%", "50%", "50%", "20%"],
              }}
              className="w-[45px] h-[45px] text-[#ffffff]"
              src={hamburger}
              alt="menu"
            />
          </div>
        </div>

        <div
          className={`border overflow-x-scroll sidebar transition-transform transform ${
            isDrawerOpen ? "translate-x-0" : "translate-x-full"
          } fixed top-0 right-0 z-10 bg-white p-6 xl:w-[40%] lg:w-[50%] md:w-[60%] w-[100%] h-[100vh]`}
        >
          <div
            className={`fixed w-[100%] top-0 right-0 h-full z-50 transform ease-in-out duration-500 transition-transform ${
              isDrawerOpen ? "translate-x-0" : "translate-x-full"
            } transition-all`}
          >
            <button
              className="m-5 float-right"
              onClick={() => setDrawerOpen(false)}
            >
              <ImCross color="black" />
            </button>

            <div className="ml-5 mr-5 grid grid-cols-1 gap-3 ">
              <div className="flex items-center gap-4">
                <motion.div
                  {...animationProps}
                  className="mt-4 flex items-center justify-center"
                >
                  <button
                    onClick={() => navigate("/login")}
                    className="h-10 w-20 rounded-md bg-primaryColor text-white"
                  >
                    Admin
                  </button>
                </motion.div>

                {/* <motion.div
                  {...animationProps}
                  className="mt-4 flex items-center justify-center">
                  <button
                    // onClick={() => navigate('/login')}
                    className='h-10 w-20 rounded-md bg-primaryColor text-white'>
                    Call Us
                  </button>
                </motion.div> */}
              </div>

              {drawerNavItems?.map((item, index) => (
                <motion.div
                  key={index}
                  {...animationProps}
                  className={`${
                    Path.pathname === item.path ? "text-green-500 ml-2" : ""
                  } flex hover:ml-2 rounded-lg items-center p-2 text-primaryTextColor text-[20px] border font-medium hover:text-primaryColor cursor-pointer transition-all duration-200 ease-in-out`}
                  onClick={() => {
                    setDrawerOpen(false);
                    navigate(item.path);
                  }}
                >
                  {item.label}
                </motion.div>
              ))}

              <div className="p-2 rounded-lg border-2">
                <div className="flex items-center ">
                  <p
                    onClick={() => {
                      if (check == false) {
                        setCheck(true);
                      } else {
                        setCheck(false);
                      }
                    }}
                    className={`${
                      check ? "text-primaryColor duration-100" : "text-black"
                    }  font-medium text-[20px] tracking-wide`}
                  >
                    Services
                  </p>

                  <RiArrowDropDownLine
                    onClick={() => {
                      if (check == false) {
                        setCheck(true);
                      } else {
                        setCheck(false);
                      }
                    }}
                    color="black"
                    size="25"
                  />
                </div>
                {check ? (
                  <div className="">
                    {serviceData?.map((each) => {
                      return (
                        <div
                          onClick={() => {
                            navigateToServiceDetails(each._id);
                            setDrawerOpen(false);
                          }}
                          className="cursor-pointer text-[0.9rem] px-3 py-1 hover:bg-primaryThemeColor text-start duration-300 text-green-600 font-medium"
                        >
                          {each.servicesName}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="p-2 rounded-lg border-2">
                <div className="flex items-center ">
                  <p
                    onClick={() => {
                      if (check2 == false) {
                        setCheck2(true);
                      } else {
                        setCheck2(false);
                      }
                    }}
                    className={`${
                      check2 ? "text-primaryColor duration-100" : "text-black"
                    }  font-medium text-[20px] tracking-wide`}
                  >
                    Products
                  </p>

                  <RiArrowDropDownLine
                    onClick={() => {
                      if (check2 == false) {
                        setCheck2(true);
                      } else {
                        setCheck2(false);
                      }
                    }}
                    color="black"
                    size="25"
                  />
                </div>
                {check2 ? (
                  <div className="">
                    {productData?.map((each) => {
                      return (
                        <div
                          onClick={() => {
                            navigateToProductDetails(each._id);
                            setDrawerOpen(false);
                          }}
                          className="cursor-pointer text-[0.9rem] px-3 py-1 hover:bg-primaryThemeColor text-start duration-300 text-green-600 font-medium"
                        >
                          {each.productName}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* <motion.div
                // {...animationProps}
                className={`bg-green-500 cursor-pointer group relative inline-block text-black  hover:text-primaryColor duration-300 tracking-wide text-[20px] border font-medium p-2`}
              >
                <div className="flex items-center bg-red-500">
                  <p>Services</p>

                  <RiArrowDropDownLine color="black" size="25" />
                </div>
                <div className=" absolute top-[2.5rem] left-0 z-100 bg-red-500 flex flex-col w-[200px] rounded-b-md opacity-30   invisible group-hover:visible group-hover:opacity-100 transition-all duration-300 transform origin-top scale-y-0 group-hover:scale-y-100">
                  {serviceData.map((each) => {
                    return (
                      <div
                        // onClick={() => {
                        //   navigateToProductDetails(each._id);
                        //   setDrawerOpen(false);
                        // }}
                        className="cursor-pointer text-[0.9rem] px-3 py-2 hover:bg-primaryThemeColor text-start duration-300"
                      >
                        {each.servicesName}
                      </div>
                    );
                  })}
                </div>
              </motion.div> */}
              {/* <motion.div
                {...animationProps}
                className={`cursor-pointer  group relative  inline-block text-black 
                 hover:text-green-500 duration-300 tracking-wide text-[20px] border font-medium p-2`}
              >
                <div className="flex items-center bg-red-500 ">
                  <p>Products</p>

                  <RiArrowDropDownLine color="black" size="25" />
                </div>
                <div className="absolute  top-full left-0 bg-yellow-300 flex flex-col w-[200px] rounded-b-md   opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-all duration-300 transform origin-top scale-y-0 group-hover:scale-y-100">
                  {productData.map((each) => {
                    return (
                      <div
                        onClick={() => {
                          navigateToProductDetails(each._id);
                          setDrawerOpen(false);
                        }}
                        className="cursor-pointer text-[0.9rem] px-3 py-2 hover:bg-primaryThemeColor text-start duration-300"
                      >
                        {each.productName}
                      </div>
                    );
                  })}
                </div>
              </motion.div> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;

// import React, { useState } from 'react';
// import { ImCross } from "react-icons/im";
// import logo from "../Assets/Logos/rmcLogo.png";
// import { motion } from "framer-motion"
// import hamburger from "../Assets/Clients/menu.png";
// import { useLocation, useNavigate } from 'react-router-dom'
// // import encryptionModule from '../Components/Common/localStorageUtils';
// import LogoutModal from '../Components/UserProfile/modals/LogoutModal';
// import { RiWhatsappFill } from 'react-icons/ri';
// import { BiSolidPhoneCall, BiSolidUserCircle } from 'react-icons/bi';

// const Navbar = () => {
//   const [isDrawerOpen, setDrawerOpen] = useState(false);
//   const [showLogoutModal, setShowLogoutModal] = useState(false);
//   const navigate = useNavigate();
//   const Path = useLocation()

//   const closeLogoutModal = () => {
//     setShowLogoutModal(false)
//   }

//   const animationProps = {
//     initial: { y: '-100%', opacity: 0 },
//     animate: { y: 0, opacity: 1, transform: 'translate3d(0,0,0)' },
//     exit: { y: '-100%', opacity: 0, transition: { delay: 0.2 } },
//     transition: { duration: 0.3, ease: 'easeInOut' },
//   };

//   return (
//     <React.Fragment>
//       <div className=' bg-[#ffffff] md:flex hidden items-center justify-between px-10 py-2'>
//         <LogoutModal
//           show={showLogoutModal}
//           onClose={closeLogoutModal} />

//         <div className='flex gap-6'>
//           <p className='flex items-center gap-2 text-[14px] tracking-wider text-[#333333]'>
//             <RiWhatsappFill size={20} color='	#25D366' /> +91-9437710574
//           </p>

//           <p className='flex items-center gap-2 text-[14px] tracking-wider text-[#333333]'>
//             <BiSolidPhoneCall size={20} color='#1E1E1E' /> +91-8917628804
//           </p>
//         </div>

//         <div className='flex items-center text-[14px] tracking-wider text-[#333333]'>
//           Singh House, Nimeishapur, Bhagatpur, Cuttack
//         </div>
//       </div>

//       <div className="bg-[#1E1E1E] shadow-lg py-2 px-10">
//         <div className='m-auto  flex  items-center justify-between'>
//           <div className="flex items-center ">
//             <img src={logo}
//               alt="Logo"
//               className="w-20 h-auto" />
//           </div>

//           <div className="hidden lg:flex items-center space-x-8 tracking-wider text-[#888888]">
//             <div className={`${Path?.pathname === '/' && "text-primaryColor border-primaryColor"} "text-primaryTextColor hover:text-primaryColor pb-2 pt-2 cursor-pointer transition-all duration-200 ease-in-out border-b-[3px] border-transparent hover:border-primaryColor hover:border-solid"`}
//               onClick={() => navigate("/")}>
//               Home
//             </div>

//             <div className="cursor-pointer group relative inline-block  hover:text-primaryColor duration-300 tracking-wide">
//               SERVICES
//               <div className="absolute z-10 top-full left-0 bg-white flex flex-col w-[200px] rounded-b-md opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-all duration-300 transform origin-top scale-y-0 group-hover:scale-y-100">
//                 <div
//                   onClick={() => navigate("/services/male_services")}
//                   className='cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300'>
//                   Male Services
//                 </div>
//                 <div
//                   onClick={() => navigate("/services/female_services")}
//                   className='text-themeBlack cursor-pointer px-3 py-2 hover:bg-primaryThemeColor text-start duration-300'>
//                   Female Services
//                 </div>
//               </div>
//             </div>

//             {/* <div className={`${Path?.pathname === '/services' && "text-primaryColor border-primaryColor"} "text-primaryTextColor hover:text-primaryColor pb-2 pt-2 cursor-pointer transition-all duration-200 ease-in-out border-b-[3px] border-transparent hover:border-primaryColor hover:border-solid"`}
//               onClick={() => navigate("/services")}
//             >
//               Services
//             </div> */}
//             <div className={`${Path?.pathname === '/about-us' && "text-primaryColor border-primaryColor"} "text-primaryTextColor hover:text-primaryColor pb-2 pt-2 cursor-pointer transition-all duration-200 ease-in-out border-b-[3px] border-transparent hover:border-primaryColor hover:border-solid"`}
//               onClick={() => navigate("/about-us")}>
//               About Us
//             </div>
//             <div className={`${Path?.pathname === '/contact-us' && "text-primaryColor border-primaryColor"} "text-primaryTextColor hover:text-primaryColor pb-2 pt-2 cursor-pointer transition-all duration-200 ease-in-out border-b-[3px] border-transparent hover:border-primaryColor hover:border-solid"`}
//               onClick={() => navigate("/contact-us")}>
//               Contact Us
//             </div>
//           </div>

//           <div className='hidden lg:flex item-center gap-4'>
//             <BiSolidUserCircle size={40} color='#28B40E' />
//             <button
//               onClick={() => navigate('/login')}
//               className='h-10 w-20 rounded-md bg-primaryColor text-white'>
//               Call Us
//             </button>
//           </div>

//           <div
//             onClick={() => setDrawerOpen(true)}
//             className="text-center ml-2 lg:hidden flex">
//             <motion.img
//               animate={{
//                 scale: [1, 2, 2, 1, 1],
//                 rotate: [0, 0, 270, 270, 0],
//                 borderRadius: ["20%", "20%", "50%", "50%", "20%"],
//               }}
//               className='w-[45px] h-[45px] text-[#ffffff]' src={hamburger} alt="" />
//           </div>
//         </div>

//         <div className={`border overflow-x-scroll sidebar transition-transform transform ${isDrawerOpen ? "translate-x-0" : "translate-x-full"} fixed top-0 right-0 z-10 bg-white p-6 xl:w-[40%] lg:w-[50%] md:w-[60%] w-[100%] h-[100vh]`}>
//           <div className={`fixed w-[100%]  top-0 right-0 h-full bg-white z-50 transform ease-in-out duration-500 transition-transform ${isDrawerOpen ? 'translate-x-0' : 'translate-x-full'} transition-all`}>
//             <button
//               className='m-5'
//               onClick={() => setDrawerOpen(false)}>
//               <ImCross />
//             </button>

//             <div className=' ml-5 mr-5 grid grid-cols-1 gap-3'>
//               <motion.div
//                 {...animationProps}
//                 className="lg:hidden  hover:ml-2 rounded-lg  flex items-center p-2 text-primaryTextColor text-[20px] border font-medium hover:text-primaryColor cursor-pointer transition-all duration-200 ease-in-out"
//                 onClick={() => {
//                   setDrawerOpen(false)
//                   navigate("/")
//                 }}>
//                 Home
//               </motion.div>

//               <motion.div
//                 {...animationProps}
//                 className={`${Path?.pathname === "/about" && "text-primaryColor ml-2"} "flex hover:ml-2 rounded-lg items-center p-2 text-primaryTextColor text-[20px] border font-medium hover:text-primaryColor cursor-pointer transition-all duration-200 ease-in-out"`}
//                 onClick={() => {
//                   setDrawerOpen(false)
//                   navigate("/about-us")
//                 }}>
//                 About
//               </motion.div>

//               <motion.div
//                 {...animationProps}
//                 className={`${Path?.pathname === "/contact-us" && "text-primaryColor ml-2"} "flex hover:ml-2 rounded-lg items-center p-2 text-primaryTextColor text-[20px] border font-medium hover:text-primaryColor cursor-pointer transition-all duration-200 ease-in-out"`}
//               >
//                 Contact Us
//               </motion.div>
//             </div>
//           </div>
//         </div>
//       </div >
//     </React.Fragment>
//   )
// }

// export default Navbar;
