import React from "react";
import background from "../../Assets/Background.png";

const BottomSection = () => {
  return (
    <div className="h-[20rem] p-[2.5rem]">
      <div
        style={{ backgroundImage: `url(${background})` }}
        className="h-[15rem] sm:pl-[2.5rem] xs:pl-[1rem] bg-center bg-cover bg-no-repeat flex justify-center flex-col
      "
      >
        <h1 className="text-primaryColor text-2xl">
          Get Your Quote or Call{" "}
          <span className="text-white xs:text-[1.1rem] sm:text-[1.4rem]">
            : +91 9776170355
          </span>
        </h1>
        <h1 className="xl:text-4xl font-medium  text-white my-3 xs:text-[1.2rem] sm:text-[1.4rem]">
          Are You Ready? Book Appointment Now!
        </h1>
        <button className="h-[3rem] w-[5rem] md:w-[7rem] rounded-lg bg-primaryColor text-white font-semibold">
          Call Us
        </button>
      </div>
    </div>
  );
};

export default BottomSection;
