import React from "react";
import banner from "../Assets/Clients/bannerImg.png";
import ExperienceCard from "../Components/About/ExperienceCard";
import Features from "../Components/About/Features";
import frame from "../Assets/Clients/Frame 565.png";
import TeamMembers from "../Components/About/TeamMembers";
import Quality from "../Components/Home/Quality";
import Technical from "../Components/Home/Technical";
import OurValues from "../Components/Home/OurValues";
import WhatsappComponent from "../Components/msgBox/WhatsappComponent";

const About = () => {
  return (
    <div className="overflow-x-hidden">
      <div className="relative">
        <img src={banner} alt="banner" className="w-full" />
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-white text-3xl md:text-5xl font-bold tracking-wider">
            About Us
          </h1>
        </div>
      </div>

      <ExperienceCard />
      <Features />

      <Quality />
      <div>
        <img src={frame} alt="frame" className="w-full" />
      </div>

      <Technical />
      <OurValues />
      <TeamMembers />
      <WhatsappComponent />
    </div>
  );
};

export default About;
