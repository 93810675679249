import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import bgImage from "../Assets/Clients/lgnBanner.png";
import logoImage from "../Assets/Logos/rmcLogo.png";
import { useForm } from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";
import { IoEyeSharp } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [load, setLoad] = useState(false);
  const [showpassword,setshowpassword]=useState(false)

  const handleLogin = (data) => {
    setLoad(true);
    console.log(data);
    // Uncomment and use the encryptionModule if needed
    // encryptionModule.encryptData('LoginToken', 'jhwegvqdug12igubukyetfwudvwjhchvdwtuv');
    // encryptionModule.encryptData('isAdmin', true);
    // encryptionModule.encryptData('User', 'response?.data?.admin');
    let userData = data;

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://singh-rmc.onrender.com/pgc-api/admins/admin-login",
      headers: {
        "Content-Type": "application/json",
      },
      data: userData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        if (response.data.success === true) {
          navigate("/admin/dashboard");
          toast.success("Logged Successfully");
          localStorage.setItem(
            "SinghRMCAdmin",
            JSON.stringify(response.data.admin)
          );
          localStorage.setItem("id", response.data.admin._id);
          setLoad(false);
          setTimeout(() => {
            localStorage.removeItem("SinghRMCAdmin");
          }, 2 * 60 * 60 * 1000);
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        toast.error(error.response.data.message);
        setLoad(false);
      });
  };

  const passwordtoggle =()=>{
    setshowpassword(!showpassword)
  }

  return (
    <div
      className="flex items-center justify-center w-full h-screen bg-gradient-to-tr from-[#00c6ff] to-[#0072ff]"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="w-full max-w-md p-8 bg-white bg-opacity-50 rounded-lg shadow-lg">
        <div className="flex justify-center mb-6">
          <img src={logoImage} alt="Logo" className="h-12 " />{" "}
          {/* Adjust the size as needed */}
        </div>
        <div className="mb-4 text-md font-medium text-start text-gray-900 tracking-wide">
          Enter Your credintials to login ..!
        </div>
        <form onSubmit={handleSubmit(handleLogin)} className="space-y-6">
          <div className="flex flex-col">
            <label
              htmlFor="email"
              className="tracking-wide mb-1 text-xs font-semibold text-gray-600"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="p-2 border text-black rounded focus:outline-none focus:shadow-outline"
              placeholder="Email"
              required
              {...register("email")}
            />
          </div>
          <div className="flex flex-col relative">
            <label
              htmlFor="password"
              className="tracking-wide mb-1 text-xs font-semibold text-gray-600"
            >
              Password
            </label>
            <input
             type={showpassword ? "text" : "password"} 
              id="password"
              name="password"
              className="p-2 border text-black rounded focus:outline-none focus:shadow-outline"
              placeholder="Password"
              required
              {...register("password")}
            />
            {showpassword ?(

              <IoEyeSharp onClick={passwordtoggle} className="absolute top-[50%] right-6 text-black"/>
            ):(

              <FaEyeSlash onClick={passwordtoggle} className="absolute top-[50%] right-6 text-black"/>
            )

            }
          </div>
          {!load ? (
            <button
              type="submit"
              className="w-[6.5rem] h-[2.5rem] mx-auto px-4 py-2 text-sm font-semibold text-white uppercase bg-primaryColor rounded flex justify-center items-center"
            >
              Login
            </button>
          ) : (
            <div className="w-[6.5rem] h-[2.5rem] mx-auto px-4 py-2 text-sm font-semibold text-white uppercase bg-primaryColor rounded flex justify-center items-center">
              <div className="loader2 mr-2"></div>
            </div>
          )}
        </form>

        <div className="flex justify-between items-center mt-4 text-center">
          <div
            onClick={() => navigate("/")}
            className="cursor-pointer text-sm text-blue-700 font-semibold"
          >
            Home
          </div>

          <a
            href="/forgot-password"
            className="cursor-pointer text-sm text-blue-700 font-semibold"
          >
            Forgot password?
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
