import React, { useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { modalSet } from "../redux/slice/productSlice";
import { useForm } from "react-hook-form";
import axios from "axios";
import { check } from "../redux/slice/productSlice";
import toast from "react-hot-toast";
import { RiAddCircleFill } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";

const CreateProductModal = () => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [keyBenifits, setKeybenefits] = useState("");
  const [keyBenifitsArr, setKeybenefitsArr] = useState([]);
  const [Uses, setUses] = useState("");
  const [UsesArr, setUsesArr] = useState([]);

  console.log(keyBenifitsArr);
  const cloudName = "dnw1ttr02";
  const upload_preset = "wjs1zyo5";
  const createProduct = (Data) => {
    setLoad(true);

    const imgObj = [
      Data.file1[0],
      Data.file2[0],
      Data.file3[0],
      Data.file4[0],
      Data.file5[0],
    ];
    console.log(imgObj);

    let newArr = [];
    imgObj.forEach((image) => {
      const file = image;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", upload_preset);

      axios
        .post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
          formData
        )
        .then((response) => {
          // console.log(response.data);
          newArr.push(response.data.secure_url);
          console.log(newArr);
          if (newArr.length == 5) {
            const productData = {
              ...Data,
              ImgUrls: newArr,
              keybenifits: keyBenifitsArr,
              whereToUse: UsesArr,
            };

            console.log(productData);
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `https://singh-rmc.onrender.com/pgc-api/product/creatProduct`,
              headers: {
                "Content-Type": "application/json",
              },
              data: productData,
            };

            axios
              .request(config)
              .then((response) => {
                console.log(response.data);
                toast.success(response.data.massage);
                dispatch(check(response.data.Product));
                setLoad(false);
                dispatch(modalSet(false));
              })
              .catch((error) => {
                console.log(error);
                toast.error(error.response.data.massage);
                setLoad(false);
                dispatch(modalSet(false));
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });

    // console.log(Data);
  };

  const createKeybenfitsArr = () => {
    if (keyBenifitsArr.includes(keyBenifits)) {
      toast.error("You have already added this item.");
      return;
    }
    const newArr = [...keyBenifitsArr];
    newArr.push(keyBenifits);
    setKeybenefitsArr(newArr);
    setKeybenefits("");
  };
  const createUses = () => {
    if (UsesArr.includes(Uses)) {
      toast.error("You have already added this item.");
      return;
    }
    const newArr = [...UsesArr];
    newArr.push(Uses);
    setUsesArr(newArr);
    setUses("");
  };

  const removeKeyBenifiits = (each) => {
    const filterArr = keyBenifitsArr.filter((item) => {
      return each != item;
    });
    setKeybenefitsArr(filterArr);
  };
  const removeUses = (each) => {
    const filterArr = UsesArr.filter((item) => {
      return each != item;
    });
    setUsesArr(filterArr);
  };

  return (
    <div className="h-[100vh] w-[100%] flex justify-center items-center z-50 bg-[#0f02025b] absolute top-0 ">
      <div
        id="careers"
        className="h-[35rem] sm:w-[35rem] xs:w-[20rem] rounded-lg relative bg-white p-[1.5rem] overflow-y-scroll"
      >
        <IoCloseCircle
          size="25"
          color="black"
          onClick={() => {
            dispatch(modalSet(false));
          }}
          className="absolute top-2 right-2"
        />

        <h1 className="text-center font-medium text-[1.5rem] text-black mb-5">
          Add Product
        </h1>
        {load ? (
          <div className="h-[100%] w-[100%]  flex flex-col justify-center items-center">
            <div className="loader1"></div>
            <p className="my-1 text-center text-black">
              Adding your product. Kindly wait....
            </p>
          </div>
        ) : (
          <div className="">
            <form
              onSubmit={handleSubmit(createProduct)}
              action=""
              className="text-black"
            >
              <label
                className="font-semibold text-[1rem]"
                htmlFor="productName"
              >
                Product Name
              </label>
              <br />
              <input
                className="p-3 border my-2 border-blue-500 h-[4rem] rounded-lg w-[100%]"
                type="text"
                name="productName"
                placeholder="Enter Your Product Name"
                required
                {...register("productName")}
              />
              <br />
              <label
                className="font-semibold text-[1rem]"
                htmlFor="description"
              >
                Description
              </label>
              <br />
              <textarea
                className="p-3  border my-2 border-blue-500 h-[8rem] rounded-lg w-[100%]"
                type="text"
                name="description"
                placeholder="Write a description her"
                required
                {...register("description")}
              />
              <br />
              <label
                className="font-semibold text-[1rem]"
                htmlFor="Keybenifits"
              >
                Key Benifits
              </label>
              <br />
              <div className="flex items-center justify-center">
                <input
                  className="p-3 border my-2 border-blue-500 h-[3rem] mr-2 rounded-lg w-[100%]"
                  type="text"
                  name="keybenifits"
                  placeholder="Write minimum 1 key benifit here."
                  value={keyBenifits}
                  onChange={(e) => {
                    setKeybenefits(e.target.value);
                  }}
                  // {...register("keybenifits")}
                />
                <RiAddCircleFill
                  onClick={createKeybenfitsArr}
                  color="#1f2544"
                  size={30}
                />
              </div>
              <div className="flex flex-wrap">
                {keyBenifitsArr?.map((each) => {
                  return (
                    <div className="flex bg-slate-300 rounded-full  justify-center items-center px-3 m-[0.1rem]">
                      <p className="text-black  mr-2 text-center">
                        {each.substring(0, 50)}
                      </p>
                      <RxCross2
                        onClick={() => {
                          removeKeyBenifiits(each);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <br />
              <label className="font-semibold text-[1rem]" htmlFor="paragraph1">
                Paragraph1
              </label>
              <br />
              <textarea
                className="p-3 border my-2 border-blue-500 h-[8rem] rounded-lg w-[100%]"
                type="text"
                name="paragaraph1"
                placeholder="Write some more details about the product here."
                required
                {...register("paragraph1")}
              />
              <br />
              <label className="font-semibold text-[1rem]" htmlFor="paragraph2">
                Paragraph2
              </label>
              <br />
              <textarea
                className="p-3 border my-2 border-blue-500 h-[8rem] rounded-lg w-[100%]"
                type="text"
                name="paragaraphSecond"
                placeholder="Second Paragraph about the product."
                required
                {...register("paragraphSecond")}
              />
              <br />
              <label
                className="font-semibold text-[1rem]"
                htmlFor="Application"
              >
                Where To Use
              </label>
              <br />
              {/* <input
                className="p-3 border my-2 border-blue-500 h-[4rem] rounded-lg w-[100%]"
                type="text"
                name="whereToUse"
                placeholder="Write some application of the product"
                required
                // {...register("whereToUse")}
              /> */}
              <div className="flex items-center justify-center">
                <input
                  className="p-3 border my-2 border-blue-500 h-[3rem] mr-2 rounded-lg w-[100%]"
                  type="text"
                  name="keybenifits"
                  placeholder="Write minimum 1 uses here."
                  value={Uses}
                  onChange={(e) => {
                    setUses(e.target.value);
                  }}
                />
                <RiAddCircleFill
                  onClick={createUses}
                  color="1f2544"
                  size={30}
                />
              </div>
              <div className="flex flex-wrap">
                {UsesArr?.map((each) => {
                  return (
                    <div className="m-1 flex bg-slate-300 rounded-full  justify-center items-center px-3 mx-[0.1rem]">
                      <p className="text-black mr-2 text-center">
                        {each.substring(0, 50)}
                      </p>
                      <RxCross2
                        onClick={() => {
                          removeUses(each);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <br />

              {/* img uPLOPAD SECTION */}
              <div className=" flex md:flex-row xs:flex-col">
                <div className="md:w-[50%] xs:w-[100%] mr-3">
                  <label className="font-medium" htmlFor="file1">
                    Image 1
                  </label>
                  <input
                    name="file1"
                    type="file"
                    required
                    {...register("file1")}
                    placeholder="Upload Your Image here."
                    className="my-2 p-3 text border h-[3rem] border-blue-500 rounded-lg w-[100%]"
                  />
                </div>
                <div className="md:w-[50%] xs:w-[100%]">
                  <label className="font-medium" htmlFor="file2">
                    Image 2
                  </label>
                  <input
                    name="file2"
                    type="file"
                    required
                    {...register("file2")}
                    placeholder="Upload Your Image here."
                    className="my-2 p-3 text border h-[3rem] border-blue-500 rounded-lg w-[100%]"
                  />
                </div>
              </div>

              {/* section 5 img upload */}
              <div className=" flex md:flex-row xs:flex-col">
                <div className="md:w-[50%] xs:w-[100%] mr-3">
                  <label className="font-medium" htmlFor="file3">
                    Image 3
                  </label>
                  <input
                    type="file"
                    name="file3"
                    required
                    {...register("file3")}
                    placeholder="Upload Your Image here."
                    className="my-2 p-3 text border h-[3rem] border-blue-500 rounded-lg w-[100%]"
                  />
                </div>
                <div className="md:w-[50%] xs:w-[100%]">
                  <label className="font-medium" htmlFor="file4">
                    Image 3
                  </label>
                  <input
                    type="file"
                    name="file4"
                    required
                    {...register("file4")}
                    placeholder="Upload Your Image here."
                    className="my-2 p-3 text border h-[3rem] border-blue-500 rounded-lg w-[100%]"
                  />
                </div>
              </div>
              {/* section 6 img upload */}

              <div className="md:w-[50%] xs:w-[100%] mr-3">
                <label className="font-medium" htmlFor="file5">
                  Image 5
                </label>
                <input
                  type="file"
                  name="file5"
                  required
                  {...register("file5")}
                  placeholder="Upload Your Image here."
                  className="my-2 p-3 text border h-[3rem] border-blue-500 rounded-lg w-[100%]"
                />
              </div>

              <button className="h-[2.5rem] w-[6.5rem] rounded-lg bg-[#1f2544] text-white font-semibold">
                Add
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateProductModal;
