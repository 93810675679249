import React from "react";
import myMap from "../../Assets/myMAP.png";

const MAP = () => {
  return (
    <>
      <div>
        <div className="h-[13rem] xs:h-[18rem] sm:h-[13rem] ">
          <h6 className="text-center py-1 pt-3 text-primaryColor">
            Our Coverage
          </h6>
          <h1 className="text-center py-1 font-bold text-4xl text-primaryTextColor">
            Services Across India
          </h1>
          <p className="text-center py-3 tracking-wide w-[29.8rem]  sm:w-[30rem] lg:w-[33rem] xl:w-[43rem] mx-auto text-secondaryTextColor xs:w-[18rem]">
            Discover the extensive range of our services available across India.
            Although this is a showcase, we proudly serve clients throughout the
            country, ensuring top-quality solutions wherever you are.
          </p>
        </div>

        <div className="h-[] flex justify-center mt-[1rem] mb-[4rem] px-3">
          <img src={myMap} alt="No image" className="w-[100%] md:w-[60%]" />

          {/* <p className="text-black">bikash pradhan</p> */}
        </div>
      </div>
    </>
  );
};

export default MAP;
