import React, { useState } from "react";
import logoImage from "../Assets/Logos/rmcLogo.png";
import bgImg from "../Assets/HomeCarousel/image4.png";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { IoEyeSharp } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa";

const ForgotPassword = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [showpassword, setshowpassword] = useState(false);
  const [showpasswordconfirm, setshowpasswordconfirm] = useState(false);

  const handleForgotPass = (data) => {
    console.log(data);
    setLoad(true);
    if (data.confirmPassword === data.newPassword) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://singh-rmc.onrender.com/pgc-api/admins/admin-forgot-password`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          toast.success("Password Updated Successfully.");
          setTimeout(() => {
            setLoad(false);
            navigate("/login");
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Couldn't Updated the password !");
          setTimeout(() => {
            setLoad(false);
            navigate("/login");
          }, 2000);
        });
    } else {
      toast.error("New Password and Confirm password must match !");
      setLoad(false);
    }
  };
  const passwordtoggle = () => {
    setshowpassword(!showpassword);
  };
  const passwordtoggleconfirm = () => {
    setshowpasswordconfirm(!showpasswordconfirm);
  };

  return (
    <div
      style={{ backgroundImage: `url(${bgImg})` }}
      className="h-screen bg-white bg-cover bg-center bg-no-repeat"
    >
      <div className=" bg-[rgba(0, 0, 0, 0.645)] h-[100%] w-[100%]  flex justify-center items-center">
        <div className="w-full max-w-md p-8 bg-white bg-opacity-50 rounded-lg shadow-lg">
          <div className="flex justify-center mb-6">
            <img src={logoImage} alt="Logo" className="w-32" />{" "}
            {/* Adjust the size as needed */}
          </div>

          <div>
            <div className="mb-4 text-md font-medium text-start text-gray-900 tracking-wide">
              Enter Your credintials to Reset the password ..!
            </div>
            <form
              onSubmit={handleSubmit(handleForgotPass)}
              className="space-y-6"
            >
              <div className="flex flex-col">
                <label
                  htmlFor="email"
                  className="tracking-wide mb-1 text-xs font-semibold text-gray-600"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="p-2 border text-black rounded focus:outline-none focus:shadow-outline"
                  placeholder="Email"
                  required
                  {...register("email")}
                />
              </div>
              <div className="flex flex-col relative">
                <label
                  htmlFor="password"
                  className="tracking-wide mb-1 text-xs font-semibold text-gray-600"
                >
                  New Password
                </label>
                <input
                  type={showpassword ? "text" : "password"}
                  id="newPassword"
                  name="newPassword"
                  className="p-2 border text-black rounded focus:outline-none focus:shadow-outline"
                  placeholder="New Password"
                  required
                  {...register("newPassword")}
                />
                {showpassword ? (
                  <IoEyeSharp
                    onClick={passwordtoggle}
                    className="absolute top-[50%] right-6 text-black"
                  />
                ) : (
                  <FaEyeSlash
                    onClick={passwordtoggle}
                    className="absolute top-[50%] right-6 text-black"
                  />
                )}
              </div>
              <div className="flex flex-col relative">
                <label
                  htmlFor="password"
                  className="tracking-wide mb-1 text-xs font-semibold text-gray-600"
                >
                  Confirm Password
                </label>
                <input
                  type={showpasswordconfirm ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  className="p-2 border text-black rounded focus:outline-none focus:shadow-outline"
                  placeholder="Confirm Password"
                  required
                  {...register("confirmPassword")}
                />
                {showpasswordconfirm ? (
                  <IoEyeSharp
                    onClick={passwordtoggleconfirm}
                    className="absolute top-[50%] right-6 text-black"
                  />
                ) : (
                  <FaEyeSlash
                    onClick={passwordtoggleconfirm}
                    className="absolute top-[50%] right-6 text-black"
                  />
                )}
              </div>
              {/* <button
                  type="submit"
                  className="w-full px-4 py-2 text-sm font-semibold text-white uppercase bg-primaryColor rounded"
                >
                  Reset Password
                </button> */}
              {load ? (
                <div className="w-[6.5rem] h-[2.5rem] mx-auto px-4 py-2 text-sm font-semibold text-white uppercase bg-primaryColor rounded flex justify-center items-center">
                  <div className="loader2 mr-2"></div>
                </div>
              ) : (
                <button
                  type="submit"
                  className="w-[6.5rem] h-[2.5rem] mx-auto px-4 py-2 text-sm font-semibold text-white uppercase bg-primaryColor rounded flex justify-center items-center"
                >
                  Reset
                </button>
              )}
            </form>

            <div className="flex justify-between items-center mt-4 text-center">
              <div
                onClick={() => navigate("/")}
                className="cursor-pointer text-sm text-blue-700 font-semibold"
              >
                Home
              </div>

              <a
                href="/login"
                className="cursor-pointer text-sm text-blue-700 font-semibold"
              >
                Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
