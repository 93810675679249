import React from "react";
import TestominialSlides from "../Home/TestominialSlides";
const FeedBack = () => {
  return (
    <div className="   sm:p-[2.5rem] xs:p-[0.5rem]">
      <div className="h-[14.5rem] sm:h-[11rem] ">
        <h6 className="text-center pb-1 py-3 text-primaryColor">
          Testimonials
        </h6>
        <h1 className="text-center py-1 font-bold text-4xl text-primaryTextColor">
          What Our Client Say
        </h1>
        <p className="text-center tracking-wide xs:w-[18.5rem]  sm:w-[30rem] lg:w-[33rem] xl:w-[43rem]  py-3 w-[29.8rem] mx-auto text-secondaryTextColor">
          Our clients consistently praise us for delivering high-quality
          construction with a professional approach.
        </p>
      </div>

      <TestominialSlides />
    </div>
  );
};

export default FeedBack;
