import React from "react";
import { IoCloseCircle } from "react-icons/io5";
import { useEffect, useState } from "react";
import {
  updateCareersModal,
  updateEditCareersModal,
  updateCurrentCareerDetails,
  updateAllCareers,
} from "../redux/slice/productSlice";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const UpdateCareers = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [load, setLoad] = useState(false);

  const careerId = useSelector((state) => {
    return state.products.careerId;
  });
  const currentEditItem = useSelector((state) => {
    return state.products.currentCareerDetails;
  });

  //All jobs

  //   Handle all the chenges inside the field
  const handleDesignation = (value) => {
    dispatch(
      updateCurrentCareerDetails((prev) => {
        return { ...prev, jobPosition: value };
      })
    );
  };
  const handleDescription = (value) => {
    dispatch(
      updateCurrentCareerDetails((prev) => {
        return { ...prev, jobDescription: value };
      })
    );
  };
  //   const handleposted = (value) => {
  //     dispatch(
  //       updateCurrentCareerDetails((prev) => {
  //         return { ...prev, jobPosition: value };
  //       })
  //     );
  //   };
  const handleLocation = (value) => {
    dispatch(
      updateCurrentCareerDetails((prev) => {
        return { ...prev, location: value };
      })
    );
  };
  const handleVacancies = (value) => {
    dispatch(
      updateCurrentCareerDetails((prev) => {
        return { ...prev, vacancies: value };
      })
    );
  };
  const handleQualification = (value) => {
    dispatch(
      updateCurrentCareerDetails((prev) => {
        return { ...prev, qualification: value };
      })
    );
  };
  const handleExperience = (value) => {
    dispatch(
      updateCurrentCareerDetails((prev) => {
        return { ...prev, experience: value };
      })
    );
  };

  //Fetch all jobs
  const getAllJobs = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/careers/getAll`,
      headers: {
        "Content-Type": "application/json",
      },
      data: "",
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        dispatch(updateAllCareers(response.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Updated a particular job....
  const updateJob = (data) => {
    setLoad(true);
    let myData = {
      id: careerId,
      jobPosition: data.designation,
      jobDescription: data.description,
      location: data.location,
      vacancies: data.vacancies,
      qualification: data.qualification,
      experience: data.experience,
    };

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/careers/edit-job`,
      headers: {
        "Content-Type": "application/json",
      },
      data: myData,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        getAllJobs();
        dispatch(updateEditCareersModal(false));
        setLoad(false);
        toast.success("Job Updated Successfully");
      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
        toast.error("Couldn't update the job !");
      });
  };

  return (
    <div className="h-[100vh] w-[100%] flex justify-center items-center z-50 bg-[#0f02025b] absolute top-0 ">
      <div
        id="careers"
        className="h-[35rem] sm:w-[35rem] xs:w-[20rem] rounded-lg relative bg-white p-[1.5rem] overflow-y-scroll"
      >
        <IoCloseCircle
          size="25"
          color="black"
          onClick={() => {
            dispatch(updateEditCareersModal(false));
          }}
          className="absolute top-2 right-2"
        />

        <h1 className="text-center font-medium text-[1.5rem] text-black mb-5">
          Update Job
        </h1>
        {load ? (
          <div className="h-[100%] w-[100%]  flex flex-col justify-center items-center">
            <div className="loader1"></div>
            <p className="my-1 text-center text-black">
              Updating your job, kindly wait....
            </p>
          </div>
        ) : (
          <div>
            <form onSubmit={handleSubmit(updateJob)}>
              <div className="mb-4">
                <label
                  htmlFor="designation"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Job Designation
                </label>
                <input
                  type="text"
                  id="designation"
                  name="designation"
                  {...register("designation")}
                  value={currentEditItem?.jobPosition}
                  onChange={(e) => {
                    handleDesignation(e.target.value);
                  }}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-[black]"
                  placeholder="Enter job designation"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="description"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Job Description
                </label>
                <textarea
                  id="description"
                  name="description"
                  rows="4"
                  {...register("description")}
                  value={currentEditItem?.jobDescription}
                  onChange={(e) => {
                    handleDescription(e.target.value);
                  }}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-[black]"
                  placeholder="Enter job description"
                  required
                />
              </div>
              {/* <div className="mb-4">
              <label
                htmlFor="posted-date"
                className="block text-gray-700 font-bold mb-2"
              >
                Posted Date
              </label>
              <input
                type="date"
                id="posted-date"
                name="posted-date"
                {...register("posted-date")}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-[black]"
                required
              />
            </div> */}
              <div className="mb-4">
                <label
                  htmlFor="location"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Location
                </label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  {...register("location")}
                  value={currentEditItem?.location}
                  onChange={(e) => {
                    handleLocation(e.target.value);
                  }}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-[black]"
                  placeholder="Enter job location"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="vacancies"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Vacancies
                </label>
                <input
                  type="number"
                  id="vacancies"
                  name="vacancies"
                  {...register("vacancies")}
                  value={currentEditItem?.vacancies}
                  onChange={(e) => {
                    handleVacancies(e.target.value);
                  }}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-[black]"
                  placeholder="Enter number of vacancies"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="qualification"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Qualification
                </label>
                <input
                  type="text"
                  id="qualification"
                  name="qualification"
                  {...register("qualification")}
                  value={currentEditItem?.qualification}
                  onChange={(e) => {
                    handleQualification(e.target.value);
                  }}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-[black]"
                  placeholder="Enter required qualification"
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="experience"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Experience (in years)
                </label>
                <input
                  type="number"
                  id="experience"
                  name="experience"
                  {...register("experience")}
                  value={currentEditItem?.experience}
                  onChange={(e) => {
                    handleExperience(e.target.value);
                  }}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-[black]"
                  placeholder="Enter required experience"
                  required
                />
              </div>
              <div className="flex justify-center items-center">
                <button
                  type="submit"
                  className="w-[7rem]   bg-custBlack text-white p-3 rounded-lg font-semibold hover:bg-blue-600"
                >
                  Update
                </button>
              </div>
            </form>{" "}
            text-[black]
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateCareers;
