import React from "react";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { IoNewspaperOutline } from "react-icons/io5";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { GiSuspicious } from "react-icons/gi";
import { useLocation, useNavigate } from "react-router-dom";
import { GiVideoConference } from "react-icons/gi";
import service from ".././Assets/service.png";
import box from ".././Assets/box.png";
import dashboard from ".././Assets/dashboard.png";
import career from ".././Assets/careerpath.png";
import gallery from ".././Assets/gallery.png";

const AdminSidebar = () => {
  const navigate = useNavigate();
  const path = useLocation();
  const pathname = path?.pathname;

  return (
    <div>
      <div className="flex flex-col gap-3 tracking-wider">
        <div
          onClick={() => {
            navigate("/admin/dashboard");
          }}
          className={`${
            pathname === "/admin/dashboard"
              ? "bg-primaryColor text-white font-semibold"
              : "bg-[#1F2544] text-white hover:bg-gray-200 hover:translate-x-2 font-normal hover:text-primaryColor"
          } flex cursor-pointer items-center gap-3 p-2 text-lg rounded transition-transform duration-200`}
        >
          <img
            src={dashboard}
            className="h-[25px] w-[25px]"
            alt="No Image"
            srcset=""
          />{" "}
          Dashboard
        </div>
        <div
          onClick={() => {
            navigate("/admin/Products");
          }}
          className={`${
            pathname === "/admin/Products"
              ? "bg-primaryColor text-white font-semibold"
              : "bg-[#1F2544] text-white hover:bg-gray-200 hover:translate-x-2 font-normal hover:text-primaryColor"
          } flex cursor-pointer items-center gap-3 p-2 text-lg rounded transition-transform duration-200`}
        >
          <img
            src={box}
            className="h-[30px] w-[30px]"
            alt="No Image"
            srcset=""
          />{" "}
          Products
        </div>
        <div
          onClick={() => {
            navigate("/admin/services");
          }}
          className={`${
            pathname === "/admin/services"
              ? "bg-primaryColor text-white font-semibold"
              : "bg-[#1F2544] text-white hover:bg-gray-200 hover:translate-x-2 font-normal hover:text-primaryColor"
          } flex cursor-pointer items-center gap-3 p-2 text-lg rounded transition-transform duration-200`}
        >
          <img
            src={service}
            alt="No Image"
            className="h-[30px] w-[30px]"
            srcset=""
          />{" "}
          Services
        </div>
        <div
          onClick={() => {
            navigate("/admin/careers");
          }}
          className={`${
            pathname === "/admin/careers"
              ? "bg-primaryColor text-white font-semibold"
              : "bg-[#1F2544] text-white hover:bg-gray-200 hover:translate-x-2 font-normal hover:text-primaryColor"
          } flex cursor-pointer items-center gap-3 p-2 text-lg rounded transition-transform duration-200`}
        >
          <img
            src={career}
            alt="No Image"
            className="h-[30px] w-[30px]"
            srcset=""
          />{" "}
          Careers
        </div>
        <div
          onClick={() => {
            navigate("/admin/gallery");
          }}
          className={`${
            pathname === "/admin/gallery"
              ? "bg-primaryColor text-white font-semibold"
              : "bg-[#1F2544] text-white hover:bg-gray-200 hover:translate-x-2 font-normal hover:text-primaryColor"
          } flex cursor-pointer items-center gap-3 p-2 text-lg rounded transition-transform duration-200`}
        >
          <img
            src={gallery}
            alt="No Image"
            className="h-[30px] w-[30px]"
            srcset=""
          />{" "}
          Gallery
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
