import React from "react";
import lgs from "../../Assets/Clients/jcb.png";
import { MdCheckBox } from "react-icons/md";

const ExperienceCard = () => {
  return (
    <React.Fragment>
      <div className="flex gap-8 flex-col md:flex-row w-full justify-center items-center bg-[#f7fffe] py-8 px-8 md:px-10 text-white">
        <div data-aos="fade-right" className="md:w-1/2 w-full">
          <img
            className="w-full h-auto rounded-lg"
            src={lgs}
            alt="luggage-pic"
          />
        </div>

        <div data-aos="fade-left" className="flex w-full md:w-1/2 md:mt-0 ">
          <div className="">
            <div
              style={{ fontFamily: "cursive" }}
              className="text-primaryColor text-xl w-full tracking-wide"
            >
              About Us
            </div>

            <div className="text-primaryTextColor text-4xl font-bold w-full mt-2 tracking-wide leading-normal">
              We are the Best Concrete Solutions.
            </div>

            <div className="text-secondaryTextColor mr-2 mt-4 w-full tracking-wide text-justify">
              At Singh RMC, we specialize in providing high-quality Pavement
              Quality Concrete (PQC) and Dry Lean Concrete (DLC) solutions. Our
              expert team ensures top-notch materials and services to meet all
              your construction needs, delivering durability and excellence in
              every project.
            </div>

            <div className="flex items-center mt-4 gap-6">
              <div>
                <div className="flex items-center gap-2 text-primaryColor text-xl font-bold w-full tracking-wide">
                  <MdCheckBox size={22} /> <span>Quality</span>
                </div>

                <div className="text-secondaryTextColor mt-2 w-full tracking-wide text-justify">
                  Singh RMC guarantees excellence and reliability in every
                  aspect of our services and products.
                </div>
              </div>

              <div>
                <div className="flex items-center gap-2 text-primaryColor text-xl font-bold w-full tracking-wide">
                  <MdCheckBox size={22} /> <span>Reliability</span>
                </div>

                <div className="text-secondaryTextColor mt-2 w-full tracking-wide text-justify">
                  Reliable and timely, Singh RMC delivers dependable performance
                  for your construction needs.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExperienceCard;
