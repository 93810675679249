import React, { useEffect, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaSquarePhone } from "react-icons/fa6";
import axios from "axios";
import WhatsappComponent from "../msgBox/WhatsappComponent";

const Careeerdetails = () => {
  const [careerdata, setCareerdata] = useState([]);
  const [openItems, setOpenItems] = useState([]);
  const [loader, setloader] = useState(false);
  const getcareersdata = () => {
    setloader(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/careers/getAll`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setCareerdata(response.data.data);
        setOpenItems(new Array(response.data.data.length).fill(false));
        setloader(false);
      })
      .catch((error) => {
        console.log(error);
        setloader(false);
      });
  };

  useEffect(() => {
    getcareersdata();
  }, []);

  const toggleDropdown = (index) => {
    const newOpenItems = [...openItems];
    newOpenItems[index] = !newOpenItems[index];
    setOpenItems(newOpenItems);
  };
  const openMessageModal = () => {
    const phoneNumber = "918917628804";
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <>
      {loader ? (
        <div className="h-[20rem]  flex flex-col justify-center items-center font-medium text-[1.1rem]">
          <div className="loader3"></div>
          <p className="font-normal mt-3 text-black">
            Loading your Page.Please wait...
          </p>
        </div>
      ) : (
        careerdata?.map((e, index) => (
          <div
            key={index}
            className="border-2  border-gray-300 w-[90%] rounded-lg mx-auto px-3 lg:py-2 xl:px-4 md:mb-6 mb-4 lg:mb-8"
          >
            <div
              className="flex justify-between cursor-pointer items-center"
              onClick={() => toggleDropdown(index)}
            >
              <h1 className="text-[1rem] md:text-[1.2rem] xl:text-[1.3rem] font-medium text-black">
                {e.jobPosition}
              </h1>
              <div>
                <RiArrowDropDownLine
                  className={`text-black text-[2rem] md:text-[2.7rem]  xl:text-[2.7rem] transition-transform duration-300 ${
                    openItems[index] ? "rotate-180" : "rotate-0"
                  }`}
                />
              </div>
            </div>

            {openItems[index] && (
              <div className="flex flex-col lg:mt-0">
                {/* Job Position */}
                <div className="lg:flex-row flex-col flex gap-1 lg:gap-4 my-2">
                  <div className="text-[#333333] py-1 w-[50%] md:w-[18%] lg:w-[11%] xl:w-[11%] bg-[#bffca9] border-none rounded-md flex items-center justify-center">
                    <p>Job Position</p>
                  </div>
                  <div className="border-b-[.2px]  p-1 border-b-gray-300 w-full lg:w-[88%] text-[#555555]">
                    {e.jobPosition}
                  </div>
                </div>
                {/* Job Description */}
                <div className="flex gap-1 lg:gap-4 my-2 lg:flex-row flex-col">
                  <div className="text-[#333333] py-1 w-[50%] md:w-[18%] lg:h-12 lg:px-1 xl:h-9 lg:w-[11%] bg-[#bffca9] border-none rounded-md flex items-center justify-center">
                    <p>Job Description</p>
                  </div>
                  <div className="border-b-[.2px] p-1 border-b-gray-300 w-full lg:w-[88%] text-[#555555]">
                    {e.jobDescription}
                  </div>
                </div>
                {/* Posted */}
                <div className="flex lg:flex-row flex-col gap-1 lg:gap-4 my-2">
                  <div className="text-[#333333] py-1 w-[50%] md:w-[18%] lg:w-[11%] bg-[#bffca9] border-none rounded-md flex items-center justify-center">
                    <p>Posted Date</p>
                  </div>
                  <div className="border-b-[.2px] p-1 border-b-gray-300 w-full lg:w-[88%] text-[#555555]">
                    {e.posted.split("T")[0]}
                  </div>
                </div>
                {/* Location */}
                <div className="flex lg:flex-row flex-col gap-1 lg:gap-4 my-2">
                  <div className="text-[#333333] py-1 w-[50%] md:w-[18%] lg:w-[11%] bg-[#bffca9] border-none rounded-md flex items-center justify-center">
                    <p>Location</p>
                  </div>
                  <div className="border-b-[.2px] p-1 border-b-gray-300 w-full lg:w-[88%] text-[#555555]">
                    {e.location}
                  </div>
                </div>
                {/* Vacancies */}
                <div className="flex lg:flex-row flex-col gap-1 lg:gap-4 my-2">
                  <div className="text-[#333333] py-1 w-[50%] md:w-[18%] lg:w-[11%] bg-[#bffca9] border-none rounded-md flex items-center justify-center">
                    <p>Vacancies</p>
                  </div>
                  <div className="border-b-[.2px] p-1 border-b-gray-300 w-full lg:w-[88%] text-[#555555]">
                    {e.vacancies}
                  </div>
                </div>
                {/* Qualification */}
                <div className="flex lg:flex-row flex-col gap-1 lg:gap-4 my-2">
                  <div className="text-[#333333] py-1 w-[50%] md:w-[18%] lg:w-[11%] bg-[#bffca9] border-none rounded-md flex items-center justify-center">
                    <p>Qualification</p>
                  </div>
                  <div className="border-b-[.2px] p-1 border-b-gray-300 w-full lg:w-[88%] text-[#555555]">
                    {e.qualification}
                  </div>
                </div>
                {/* Experience */}
                <div className="flex gap-1 lg:gap-4 lg:flex-row flex-col my-2">
                  <div className="text-[#333333] py-1 w-[50%] md:w-[18%] lg:w-[11%] bg-[#bffca9] border-none rounded-md flex items-center justify-center">
                    <p>Experience</p>
                  </div>
                  <div className="border-b-[.2px] p-1 border-b-gray-300 w-full lg:w-[88%] text-[#555555]">
                    {e.experience}
                  </div>
                </div>
                {/* Contact and Apply */}
                <div className="flex items-center justify-between mt-2 mb-2 lg:mb-0">
                  <p className="text-black text-[1rem] lg:text-[1.2rem] font-medium flex gap-1 md:gap-2 items-center">
                    <span>
                      <FaSquarePhone className="lg:text-[1.6rem] text-[1.3rem]" />
                    </span>{" "}
                    +91 9776170355
                  </p>
                  <button
                    className="lg:py-2 px-2 py-1 lg:px-4 bg-blue-500 rounded-md text-white"
                    onClick={openMessageModal}
                  >
                    Apply now
                  </button>
                </div>
              </div>
            )}
            <WhatsappComponent />
          </div>
        ))
      )}
    </>
  );
};

export default Careeerdetails;
