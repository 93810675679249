import React from "react";
import Frame7 from "../../Assets/Frame7.png";
import frame1 from "../../Assets/Frame 526.png";
import frame2 from "../../Assets/Frame 527.png";
import frame3 from "../../Assets/Frame 528.png";

const SectionOne = () => {
  return (
    <div className="xl:h-[25rem] xs:h-auto p-[2.5rem]">
      <div
        style={{ backgroundImage: `url(${Frame7})` }}
        className="h-[100%] bg-black bg-cover bg-no-repeat bg-center flex items-center justify-between xs:flex-col  lg:flex-row pl-[0.8rem] pr-[1.3rem]"
      >
        <img src={frame1} className="my-6" alt="No Image" />
        <img src={frame2} className="my-6" alt="No Image" />
        <img src={frame3} className="my-6" alt="No Image" />
      </div>{" "}
      className="my-6"
    </div>
  );
};

export default SectionOne;
