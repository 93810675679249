import React from "react";
import construction from "../../Assets/construction1.webp";
import construction2 from "../../Assets/construction2.png";
import construction3 from "../../Assets/construction3.png";
import construction4 from "../../Assets/construction4.png";

const ServicesOffered = () => {
  return (
    <div className="h-[84rem] xs:h-[155rem]  sm:h-auto md:h-auto xl:grid xl:grid-cols-2 xl:grid-rows-2 gap-5 pb-2 md:pb-4 xl:pb-12 p-[2.5rem] xs:pt-[0.7rem] xs:px-[1.5rem] xs:flex xs:flex-wrap xs:justify-center">
      <div className="xl:w-[100%] xl:h-[40rem] rounded-lg bg-white xs:h-[36rem] sm:w-[17rem] sm:h-[34rem] xs:w-[19rem] shadow-md">
        <div
          style={{ backgroundImage: `url(${construction})` }}
          className="bg-cover bg-center  relative bg-no-repeat xs:h-[60%] xl:h-[70%] md:h-[60%] rounded-lg   "
        >
          <div className="xs:w-[14rem] xs:ml-[-7rem] sm:top-[18.6rem] xs:top-[20rem] xs:h-[3rem] xs:text-[1rem]  md:top-[18.6rem] md:text-[1rem] flex justify-center font-semibold xl:text-[1.5rem] items-center text-white xl:h-[4rem] xl:w-[36rem]  rounded-lg bg-primaryColor absolute xl:top-[25rem] left-[50%] xl:ml-[-18rem]  md:w-[13rem]  md:h-[3rem] md:ml-[-6.5rem] ">
            <p>Ready Mix Concrete</p>
          </div>
        </div>
        <p className="xs:mt-[3rem] xs:px-2 tracking-wide xs:text-[15px] md:mt-[2rem] md:text-[0.9rem]  text-center text-secondaryTextColor xl:mt-[2.7rem] xl:text-[1.1rem] xl:px-[3rem] ">
          Ready-mix concrete (RMC) is a type of concrete that is manufactured in
          a batch plant according to a set engineered mix design. This type of
          concrete is then delivered to a work site, typically in large trucks
          equipped with rotating drums
        </p>
      </div>
      <div className="xl:w-[100%] xl:h-[40rem]  rounded-lg bg-white xs:h-[36rem] sm:w-[17rem] sm:h-[34rem] xs:w-[19rem]  shadow-md">
        <div
          style={{ backgroundImage: `url(${construction2})` }}
          className="bg-cover bg-center  relative bg-no-repeat xs:h-[60%] xl:h-[70%] md:h-[60%] rounded-lg   bg-red-500"
        >
          <div className="xs:w-[14rem] xs:ml-[-7rem] sm:top-[18.6rem] xs:top-[20rem] xs:h-[3rem] xs:text-[1rem] md:top-[18.6rem] md:text-[1rem] flex justify-center font-semibold xl:text-[1.5rem] items-center text-white xl:h-[4rem] xl:w-[36rem]  rounded-lg bg-primaryColor absolute xl:top-[25rem] left-[50%] xl:ml-[-18rem]    md:w-[13rem] md:h-[3rem] md:ml-[-6.5rem] ">
            <p>PQC & DLC</p>
          </div>
        </div>
        <p className="xs:mt-[2rem]  xs:px-2 tracking-wide xs:text-[15px] md:mt-[2rem] md:text-[0.9rem]  text-center text-secondaryTextColor xl:mt-[2.7rem] xl:text-[1.1rem] xl:px-[3rem] ">
          Dry Lean Concrete (DLC) is a type of concrete with a relatively low
          cement content. Meaning that it has more aggregate and less cement
          compared to traditional concrete.PQC incorporates polymers into the
          concrete mix to enhance its properties.
        </p>
      </div>
      <div className="xl:w-[100%] xl:h-[40rem] rounded-lg bg-white xs:h-[36rem] sm:w-[17rem] sm:h-[34rem] xs:w-[19rem]  shadow-md">
        <div
          style={{ backgroundImage: `url(${construction3})` }}
          className="bg-cover bg-center  relative bg-no-repeat xs:h-[60%] xl:h-[70%] md:h-[60%] rounded-lg  "
        >
          <div className="xs:w-[14rem] xs:ml-[-7rem] sm:top-[18.6rem] xs:top-[20rem] xs:h-[3rem] xs:text-[1rem] md:top-[18.6rem] md:text-[1rem] text-white font-semibold xl:text-[1.5rem] flex justify-center items-center xl:h-[4rem] xl:w-[36rem]  rounded-lg bg-primaryColor absolute xl:top-[25rem]  left-[50%] xl:ml-[-18rem]  md:w-[13rem] md:h-[3rem] md:ml-[-6.5rem] ">
            <p>RMC Equipment On Rent</p>
          </div>
        </div>
        <p className="xs:mt-[2.4rem] xs:px-2 tracking-wide xs:text-[15px] md:mt-[2rem] md:text-[0.9rem]  text-center text-secondaryTextColor xl:mt-[2.7rem] xl:text-[1.1rem] xl:px-[3rem] ">
          Renting equipment for Ready-Mix Concrete (RMC) operations can be a
          cost-effective solution for construction projects. Here’s an overview
          of the common RMC equipment that is available for rent and some
          considerations for renting this equipment.
        </p>
      </div>
      <div className="xl:w-[100%] xl:h-[40rem]  rounded-lg bg-white xs:h-[36rem] sm:w-[17rem] sm:h-[34rem] xs:w-[19rem]  shadow-md">
        <div
          style={{ backgroundImage: `url(${construction4})` }}
          className="bg-cover bg-center bg-no-repeat xs:h-[60%] xl:h-[70%] md:h-[60%] rounded-lg relative "
        >
          <div className=" xs:w-[14rem] xs:text-[1rem] xs:ml-[-7rem] sm:top-[18.6rem] xs:top-[20rem] xs:h-[3rem] md:top-[18.6rem] md:text-[1rem] text-white font-semibold xl:text-[1.5rem] flex justify-center items-center xl:h-[4rem]  xl:w-[36rem] rounded-lg bg-primaryColor absolute xl:top-[25rem]  left-[50%] xl:ml-[-18rem]  md:w-[13rem] md:h-[3rem] md:ml-[-6.5rem] ">
            <p>Real Estate</p>
          </div>
        </div>
        <p className="xs:mt-[3rem] xs:px-2 tracking-wide xs:text-[15px] md:mt-[2rem] md:text-[0.9rem] text-center text-secondaryTextColor xl:mt-[2.7rem] xl:text-[1.1rem] xl:px-[3rem] ">
          Real estate encompasses the buying, selling, and leasing of land and
          properties, including residential, commercial, and industrial spaces.
          It plays a critical role in the economy, offering opportunities for
          investment and wealth creation.
        </p>
      </div>
    </div>
  );
};

export default ServicesOffered;
