import React from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import whatsapp from "../../Assets/HomeCarousel/whatsapplogo.png";
const WhatsappComponent = () => {
  const openMessageModal = () => {
    const phoneNumber = "919776170355";
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <button
      className="fixed right-4 bottom-4 z-40 p-2  rounded-full shadow-lg  hover:scale-105 ease-in-out duration-300 focus:outline-none transition-transform transform-gpu"
      onClick={openMessageModal}
    >
      <img src={whatsapp} alt="" className="w-[3rem] object-cover" />
      {/* <IoLogoWhatsapp size={32} color="#10b416" /> */}
    </button>
  );
};

export default WhatsappComponent;
