import { Navigate } from "react-router-dom";
import About from "../Screens/About";
import AdminDashboard from "../Screens/Admin/AdminDashboard";
import Contact from "../Screens/Contact";
import Home from "../Screens/Home";
import Login from "../Screens/Login";
import Logout from "../Screens/Logout";
import MyProductDetails from "../Screens/MyProductDetails";
import AdminProductPage from "../Screens/Admin/AdminProductPage";
import ForgotPassword from "../Screens/ForgotPassword";
import AdminServicesPage from "../Screens/Admin/AdminServicesPage";
import Profile from "../Screens/Admin/Profile";
import ServicesDetails from "../Screens/ServicesDetails";
import Careerpage from "../Screens/careerpage";
import Galery from "../Screens/galery";
import AdminGallery from "../Screens/Admin/AdminGallery";
import AdminCareers from "../Screens/Admin/AdminCareers";

const userRoutes = [
  { path: "/", element: <Home /> },
  { path: "/services", element: <About /> },
  { path: "/about-us", element: <About /> },
  { path: "/contact-us", element: <Contact /> },
  { path: "/product/details/:id", element: <MyProductDetails /> },
  { path: "/service/details/:id", element: <ServicesDetails /> },
  { path: "/career", element: <Careerpage /> },
  { path: "/gallery", element: <Galery /> },
];

const adminRoutes = [
  {
    path: "/admin/",
    exact: true,
    element: <Navigate to="/admin/dashboard" />,
  },
  { path: "/admin/dashboard", element: <AdminDashboard /> },
  { path: "/admin/Products", element: <AdminProductPage /> },
  { path: "/admin/services", element: <AdminServicesPage /> },
  { path: "/admin/profile", element: <Profile /> },
  { path: "/admin/careers", element: <AdminCareers /> },
  { path: "/admin/gallery", element: <AdminGallery /> },
];

const publicRoutes = [
  { path: "/login", element: <Login /> },
  { path: "/logout", element: <Logout /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
];

export { userRoutes, adminRoutes, publicRoutes };
