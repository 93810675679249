import React from "react";

const Technical = () => {
  return (
    <div className="text-black bg-white px-[2.5rem] pb-[2.5rem]">
      <h1 className="text-4xl font-bold text-center my-8 text-[#333333]">
        Technical Capacity
      </h1>
      <p className="my-3 text-center text-[#333333]  leading-7">
        At <span className="font-bold">SINGH RMC INFRA PVT LTD </span> .
        Concrete ready mix concrete is produced in its fully automated
        computerized concrete batching plants at multiple locations in future
        across PAN India. Here we manufacture and supplies concrete mixes, which
        are designed in its well-equipped laboratories as per established
        procedure to comply with various provisions of Indian standard. And our
        experienced logistics team works closely worked with client to ensure
        that the concrete of requisite quality is delivered on time and in the
        appropriate quantities
      </p>
    </div>
  );
};

export default Technical;
