import React from "react";
import ownerImg from "../../Assets/ownerImg.png";
import maneger from "../../Assets/HomeCarousel/1.png";
import director from "../../Assets/HomeCarousel/2.png";

const teamMembers = [
  {
    name: "Bibhu Singh",
    designation: "Chief of Technician",
    image:
      "https://images.hindustantimes.com/rf/image_size_640x362/HT/p1/2015/05/27/Incoming/Pictures/1351957_Wallpaper2.jpg",
  },
  {
    name: "Tapan Kumar",
    designation: "Technician",
    image:
      "https://img.freepik.com/free-photo/indian-man-working-male-yellow-vest-man-with-mobile-phone_1157-47551.jpg",
  },
  {
    name: "S Pandey",
    designation: "Technician",
    image:
      "https://www.shutterstock.com/image-photo/civil-engineer-working-site-600nw-1895693278.jpg",
  },
  {
    name: "Sunny Rao",
    designation: "Technical Head",
    image:
      "https://img.freepik.com/free-photo/man-safety-equipment-work_23-2148976356.jpg",
  },
];

const TeamMembers = () => {
  return (
    <div className="bg-gray-100 py-10 px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-8">
        <div
          style={{ fontFamily: "cursive" }}
          className="text-primaryColor text-xl w-full tracking-wide"
        >
          Team
        </div>
        <div className="text-primaryTextColor text-4xl font-bold w-full mt-0 tracking-wide leading-normal">
          Our Team Members
        </div>
        <div className="text-secondaryTextColor mr-2 mt-2 tracking-wide text-center">
          Meet the dedicated team behind our success.
        </div>
      </div>

      <div className="flex justify-center lg:flex-nowrap xs:flex-wrap lg:gap-5">
        <div className="md:h-[25rem] lg:h-[21rem] xl:h-[25rem]  lg:w-[50%] xs:w-[100%] rounded-lg m-2 bg-cover  ">
          <div className="h-[100%] rounded-md rounded-t-lg flex  justify-center items-center ">
            <img
              src={maneger}
              alt="No image"
              className="h-full w-full rounded-md"
            />
          </div>
          {/* <div className="h-[20%] bg-primaryColor rounded-b-lg text-white flex flex-col justify-center items-center">
            <p className="text-lg ">MAMATA SINGH</p>
            <p>Director</p>
          </div> */}
        </div>
        <div className="md:h-[25rem] lg:h-[21rem] xl:h-[25rem] lg:w-[50%] xs:w-[100%] rounded-lg m-2 bg-cover  ">
          <div className="h-[100%] rounded-t-lg flex justify-center items-center">
            <img
              src={director}
              alt="No image"
              className="h-full w-full rounded-md "
            />
          </div>
          {/* <div className="h-[20%] bg-primaryColor rounded-b-lg text-white flex flex-col justify-center items-center">
            <p className="text-lg ">MONORANJAN SINGH</p>
            <p>Managing Director</p>
          </div> */}
        </div>
      </div>
      <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
        {teamMembers.map((member, index) => (
          <div
            data-aos="flip-left"
            key={index}
            className="flex flex-col items-center bg-white p-6 rounded-lg shadow-md"
          >
            <img
              src={member.image}
              alt={`${member.name}`}
              className="w-32 h-32 rounded-full mb-4 object-cover"
            />
            <h3 className="text-xl font-bold text-gray-900">{member.name}</h3>
            <p className="text-gray-600">{member.designation}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamMembers;
