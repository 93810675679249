import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import LWC from "../../Assets/LWC.png";
import { RiEdit2Fill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";
import { IoCloseCircle } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
  modalSet,
  updateModalOpen,
  saveCurrentProductDetails,
} from "../../Components/redux/slice/productSlice";
import { productId } from "../../Components/redux/slice/productSlice";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const AdminProductPage = () => {
  const navigate = useNavigate();
  const userData = localStorage.getItem("SinghRMCAdmin");

  console.log(userData);
  if (userData == null) {
    navigate("/login");
  }
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [modal, setModal] = useState(false);
  const [check, setCheck] = useState(false);
  let data = "";

  const newProduct = useSelector((state) => {
    return state.products.check;
  });
  const check2 = useSelector((state) => {
    return state.products.check2;
  });

  const getAllProducts = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://singh-rmc.onrender.com/pgc-api/getallproducts//getProducts`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setProducts(response.data.product);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Delete product on click
  const deleteProduct = (id) => {
    // let confirmation = confirm(
    //   "Are you sure you want to delete this product ?"
    // );
    if (
      window.confirm("Are you sure you want to delete this product ?") == true
    ) {
      toast("Deteting Please Wait...");
      setTimeout(() => {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `https://singh-rmc.onrender.com/pgc-api/deleteProduct/delete/${id}`,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            console.log(response.data);
            toast.success("Deleted Successfully");
            const filteredItems = products.filter((eachItem) => {
              return eachItem._id != id;
            });
            setProducts(filteredItems);
          })
          .catch((error) => {
            console.log(error);
            toast.error("Couldn't delete the item!");
          });
      }, 4000);
    }
  };

  ///
  const productIndexAndDetails = (item, index) => {
    dispatch(saveCurrentProductDetails({ ...item, index }));
  };

  useEffect(() => {
    let userData = localStorage.getItem("SinghRMCAdmin");
    console.log(userData);
    if (!userData) {
      navigate("/login");
      return;
    }
    getAllProducts();
  }, [newProduct, check2]);

  return (
    <div className="text-black relative  ">
      {modal ? (
        <div className="h-[100vh] w-[100%] flex justify-center items-center bg-[#0f02025b] absolute top-0 ">
          <div className="h-[35rem] w-[35rem] rounded-lg relative bg-white">
            <IoCloseCircle
              size="25"
              onClick={() => {
                setModal(false);
              }}
              className="absolute top-2 right-2"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="flex justify-between items-center mb-3">
        <h1 className="font-bold sm:text-[1.3rem] xs:text-[1.1rem] ">
          Product Lists
        </h1>
        <button
          onClick={() => {
            dispatch(modalSet(true));
          }}
          className="bg-[#1f2544] rounded-lg w-[8rem] h-[2.6rem] font-semibold text-white"
        >
          Add Product
        </button>
      </div>

      <div>
        {products?.length == 0 ? (
          <div className="h-[35rem]  flex flex-col justify-center items-center font-medium text-[1.1rem]">
            <div className="loader3"></div>
            <p className="font-normal mt-3">
              Fetching your Products.Please wait...
            </p>
          </div>
        ) : (
          <div>
            <div className="text-black flex flex-wrap justify-center md:gap-6 lg:gap-10 xl:gap-5">
              {products && products.length > 0 ? (
                products.map((each, index) => {
                  return (
                    <motion.div
                      key={index}
                      initial={{ scale: 1 }}
                      whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.2 },
                      }}
                      className="w-[21rem] md:w-[22rem] xl:w-[32%] bg-white rounded-lg shadow-md p-3 my-2 "
                    >
                      <div
                        style={{
                          backgroundImage: `url(${each?.ImgUrls?.[0]})`,
                        }}
                        className="h-[13rem] w-[100%] bg-cover rounded-lg bg-no-repeat bg-center"
                      ></div>
                      <div className="relative">
                        <h1 className="font-bold my-2">
                          {each.productName.substring(0, 30)}...
                        </h1>
                        <p className="mt-2 xl:text-[0.9rem] text-secondaryTextColor">
                          {each.description.substring(0, 100)}...{" "}
                        </p>
                        <div className="flex items-center justify-center">
                          <button className="mr-3">
                            <RiEdit2Fill
                              onClick={() => {
                                dispatch(updateModalOpen(true));
                                dispatch(productId(each._id));
                                productIndexAndDetails(each, index);
                              }}
                              size={25}
                              color="#1f2544"
                            />
                          </button>
                          <button>
                            <MdDelete
                              onClick={() => {
                                deleteProduct(each._id);
                              }}
                              size={25}
                              color="red"
                            />
                          </button>
                        </div>
                      </div>
                    </motion.div>
                  );
                })
              ) : (
                <div className="h-[20rem] flex justify-center items-center text-gray-500 text-lg font-semibold mt-10">
                  No products found !
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminProductPage;
