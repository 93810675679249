import React, { useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
// import chat from "../Assets/chat_img.png"
import { BsEmojiWink } from "react-icons/bs";
// import ChatModal from '../Components/Modals/ChatModal';
// import encryptionModule from '../Components/Common/localStorageUtils';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {
    const path = useLocation()
    // console.log(path?.pathname)
    const [isModalOpen, setModalOpen] = useState(false);
    const validUserTypes = ['student', 'research-organizer'];
    // var existUser = encryptionModule.decryptData('User');
    const MessageButton = () => {
        const [isHovered, setHovered] = useState(false);

        return (
            <div
                className="w-[70px] fixed right-4 bottom-4 p-1 rounded-full shadow-lg hover:bg-gray-300 hover:scale-[1.2] ease-in-out hover:duration-300 focus:outline-none transition-transform transform-gpu"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={() => setModalOpen(true)}
            >
                {/* <img className='w-[100%]' src={chat} alt="" /> */}

                {isHovered && (
                    <div className="absolute bottom-full ml-[-120px] mb-[-50px] bg-gray-500 text-white p-1 rounded-md text-sm  flex items-center gap-2">
                        Ask Question <BsEmojiWink />
                    </div>
                )}
            </div>
        );
    };
    return (
        <React.Fragment>
            {
                path?.pathname?.slice(0, 8) !== '/payment' &&
                <Navbar />
            }


            <main>
                {children}

                {/* {
                    validUserTypes.includes(existUser?.userType)
                    &&
                    <MessageButton />
                } */}

                <Footer />
            </main>
            {/* {
                path?.pathname?.slice(0, 8) !== '/payment' &&
                // <Footer />
            } */}

            {/* {
                validUserTypes.includes(existUser?.userType)
                &&

                <ChatModal
                    isOpen={isModalOpen}
                    onClose={() => setModalOpen(false)}
                />
            } */}
        </React.Fragment>
    );
}

export default Layout
