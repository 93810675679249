import React from "react";

const Quality = () => {
  return (
    <div className="text-black bg-white px-[2.5rem] pb-[2.5rem]">
      <h1 className="text-4xl font-bold text-center my-8 text-[#333333]">
        Quality
      </h1>
      <p className="my-3 text-center text-[#333333] leading-7 tracking-wide">
        Quality council of India (QCI) hastaken an initiative to establish an
        independent voluntary RMC plant certification scheme in the country to
        assure quality in operations and processes of RMC plants. This scheme
        was developed with the active participation and technical support from
        ready mix concrete manufactures Association (RMCMA), a Mumbai based
        non-profit industry organization of leading ready-mix concrete (RMC)
        producers in India, Building materials & Technology promotion council
        (BMTPC) under Ministry of Housing & urban poverty Alleviation,
        Government of India, and various other stakeholders.
      </p>
      <p className="text-[#333333] my-3 leading-7 tracking-wide">
        {" "}
        <span className="font-bold">.</span> Our Effort is to put ourselves in
        harmony with the standard of this Organisation and deliver Outstanding
        Quality of concrete and service to our Esteemed customer with help of: -
      </p>
      <p className="text-[#333333] my-3 leading-7 tracking-wide">
        <span className="font-bold">.</span> Fully equipped laboratories at all
        plants to carry out various tests on concret and on constituent raw
        materials.
      </p>
      <p className="text-[#333333] my-3 leading-7 tracking-wide">
        <span className="font-bold">.</span> Concrete mixes of various grades
        are designed in laboratory as per established procedure to comply with
        BIS specification.
      </p>
      <p className="text-[#333333] my-3 leading-7 tracking-wide">
        <span className="font-bold">.</span> The quality assurance systems and
        procedures implemented by SRIPL concrete monitors every stage of entire
        business process. Right from the processing of order, purchase/control
        of materials and concrete mix design to production, sales, delivery, and
        control of concrete.
      </p>

      <p className="my-3 tracking-wide">
        <span className="font-bold">. Compressive strength of concrete: </span>
        Cubes are casted at the plant as well as at the site. customer can
        therefore ascertain that the concrete he receives at his site has the
        designed strength.
      </p>
      <p className="my-3 tracking-wide">
        <span className="font-bold">. Durability: </span>: Scientifically
        designed mixesto ensure better durability of the structures.
      </p>
      <p className="my-3 tracking-wide">
        <span className="font-bold">. Workability: </span>
        Slump is tested immediately after the production and at the time of
        pour. Required slump is ensured.
      </p>
      <p className="my-3 tracking-wide">
        <span className="font-bold">. </span>
        Test Certificate are issued regularly for testing of concrete.
      </p>
    </div>
  );
};

export default Quality;
