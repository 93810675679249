import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  openModal: false,
  check: "",
  updateModal: false,
  currentProductDetails: {},
  id: "",
  check2: false,
  openServiceMoodal: false,
  openEditServiceModal: false,
  serviceDetails: {},
  careersModal: false,
  allCareers: [],
  editCareersModal: false,
  currentCareerDetails: {},
  careerId: "",
};

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    updateCareerid: (state, action) => {
      state.careerId = action.payload;
    },
    updateCurrentCareerDetails: (state, action) => {
      state.currentCareerDetails = action.payload;
    },
    updateEditCareersModal: (state, action) => {
      state.editCareersModal = action.payload;
    },
    updateAllCareers: (state, action) => {
      state.allCareers = action.payload;
    },
    updateCareersModal: (state, action) => {
      state.careersModal = action.payload;
    },
    modalSet: (state, action) => {
      state.openModal = action.payload;
    },
    check: (state, action) => {
      state.check = action.payload;
    },
    updateModalOpen: (state, action) => {
      state.updateModal = action.payload;
    },
    saveCurrentProductDetails: (state, action) => {
      state.currentProductDetails = action.payload;
    },
    productId: (state, action) => {
      state.id = action.payload;
    },
    updateCheck: (state, action) => {
      state.check2 = action.payload;
    },
    serviceModalSet: (state, action) => {
      state.openServiceMoodal = action.payload;
    },
    editServiceModal: (state, action) => {
      state.openEditServiceModal = action.payload;
    },
    serviceDetails: (state, action) => {
      state.serviceDetails = action.payload;
    },
  },
});

export const {
  modalSet,
  check,
  productId,
  updateModalOpen,
  saveCurrentProductDetails,
  updateCheck,
  serviceModalSet,
  editServiceModal,
  serviceDetails,
  updateCareersModal,
  updateAllCareers,
  updateEditCareersModal,
  updateCurrentCareerDetails,
  updateCareerid,
} = productSlice.actions;
export default productSlice.reducer;
