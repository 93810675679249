import React, { useState } from "react";
import dummyImg from "../../Assets/dummImg.png";
import Services from "../Home/Services";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SCC from "../../Assets/SCC.png";
import SFRC from "../../Assets/SFRC.png";
import LWC from "../../Assets/LWC.png";
import HSC from "../../Assets/HSC.png";
import DLCPQC from "../../Assets/DLC&PQC.png";
import GRVCUT from "../../Assets/Groove&Cutting.png";
import { motion } from "framer-motion";

const Products = () => {
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const [load, setLoad] = useState(false);
  //   const randomArr = [1, 2, 3, 4, 5, 6];

  const arrOfImgs = [SCC, SFRC, LWC, HSC, DLCPQC, GRVCUT];

  const getAllproducts = () => {
    setLoad(true);
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://singh-rmc.onrender.com/pgc-api/getallproducts/getProducts`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data.product);
        setProductData(response.data.product);
        setLoad(false);
      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
      });
  };

  const navigateToProductDetails = (id) => {
    navigate(`/product/details/${id}`);
  };

  useEffect(() => {
    getAllproducts();
  }, []);

  return (
    <div className="px-[2.5rem] xs:px-[1.5rem]">
      <div className="h-[13rem] xs:h-[14rem] xs:mb-4 sm:mb-0 sm:h-[10rem] ">
        {/* <h6 className="text-center py-3 text-primaryColor">Services</h6> */}
        <h1 className="text-center  py-3 font-bold text-4xl text-primaryTextColor">
          Our Products
        </h1>
        <p className="text-center py-2 tracking-wide w-[29.8rem]   lg:w-[45rem] xl:w-[58rem] mx-auto text-secondaryTextColor xs:w-auto">
          Our construction company specializes in building and infrastructure
          projects, providing services such as design, engineering, and project
          management. They handle residential, commercial, and industrial
          constructions throughout the project lifecycle.
        </p>
      </div>

      {load ? (
        <div className="h-[6rem] flex justify-center items-center">
          <div className="loader3"></div>
        </div>
      ) : (
        <div className="text-black flex flex-wrap justify-center">
          {productData?.map((each, index) => {
            return (
              <motion.div
                initial={{ scale: 1 }}
                whileHover={{
                  scale: 1.03,
                  transition: { duration: 0.2 },
                }}
                onClick={() => {
                  navigateToProductDetails(each._id);
                }}
                className="sm:w-[18rem] md:w-[20rem]  bg-white rounded-lg mx-2 shadow-md p-3 my-2 cursor-pointer "
              >
                <div
                  style={{
                    backgroundImage: `url(${each?.ImgUrls?.[0]})`,
                  }}
                  className=" xs:h-[15rem] xs:mt-5 sm:mt-0 rounded-lg bg-cover bg-no-repeat bg-center"
                ></div>

                <h1 className="font-bold my-2">{each.productName}</h1>
                <p className="mt-2 xl:text-[1.1rem] text-secondaryTextColor">
                  {each.description.substring(0, 100)}...{" "}
                </p>
              </motion.div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Products;
