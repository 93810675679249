import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Layout from './Layout';
// import encryptionModule from '../Components/Common/localStorageUtils';
import { useNavigate } from 'react-router-dom';

const WithUserLayout = () => {
  const navigate = useNavigate();
  // const existUser = encryptionModule.decryptData("User");
  // console.log("decryptData", existUser?.userType);
  // useEffect(() => {
  //   if (existUser?.userType === 'admin') {
  //     navigate('/admin');
  //   }
  // }, [existUser, navigate])

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default WithUserLayout;
