import React from "react";
import highQualityImage from "../../Assets/Clients/features1.png";
import customerSatisfactionImage from "../../Assets/Clients/features2.png";
import safetyFirstImage from "../../Assets/Clients/features3.png";

const Features = () => {
  return (
    <div className="bg-[#eceaea] mx-auto px-10 py-8">
      <div className="text-center mb-8">
        <div
          style={{ fontFamily: "cursive" }}
          className="text-primaryColor text-xl w-full tracking-wide"
        >
          Features
        </div>
        <div className="text-primaryTextColor text-4xl font-bold w-full mt-1 tracking-wide leading-normal">
          Why Choose Us
        </div>
        <div className="text-secondaryTextColor mr-2 mt-4 tracking-wide text-center">
          At Singh RMC, we stand out as a premier concrete provider due to our
          unwavering commitment to quality, reliability, and customer
          satisfaction. Our state-of-the-art equipment and experienced team
          ensure that every project, big or small, is completed with the highest
          standards of excellence. We prioritize safety and efficiency,
          delivering top-notch concrete solutions on time, every time. Choose
          Singh RMC for a trusted partner in your construction endeavors.
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
        <div
          data-aos="fade-right"
          className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md"
        >
          <img
            src={highQualityImage}
            alt="High Quality"
            className="w-20 h-20 mb-4"
          />
          <h2 className="sm:text-lg xs:text-sm  font-bold mb-2 text-center text-black sm:px-[3rem] xs:px-0">
            Producing Concrete of the Highest Quality
          </h2>
          <p className="text-gray-600 text-center">
            Prioritizing safety in every aspect of our work.
          </p>
        </div>

        <div
          data-aos="zoom-in"
          className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md"
        >
          <img
            src={customerSatisfactionImage}
            alt="Customer Satisfaction"
            className="w-20 h-20 mb-4"
          />
          <h2 className="sm:text-lg xs:text-sm font-bold mb-2 text-center text-black sm:px-[3rem] xs:px-0">
            Reliable Partner for Your Construction
          </h2>
          <p className="text-gray-600 text-center">
            Dedicated to providing excellent customer service.
          </p>
        </div>

        <div
          data-aos="fade-left"
          className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md"
        >
          <img
            src={safetyFirstImage}
            alt="Safety First"
            className="w-20 h-20 mb-4"
          />
          <h2 className="sm:text-lg xs:text-sm  font-bold mb-2 text-center text-black sm:px-[3rem] xs:px-0">
            The Leading Manufacturer and Concrete Company
          </h2>
          <p className="text-gray-600 text-center">
            Ensuring top quality materials and services for all projects.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Features;
