import React from "react";
import profile from "../../Assets/profile.jpg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();

  // const navigate = useNavigate();
  // const token = localStorage.getItem("SNadminToken");
  // if (!token) {
  //   navigate("/auth/log-in");
  // }

  const user = JSON.parse(localStorage.getItem("SinghRMCAdmin"));
  console.log(user);
  useEffect(() => {
    let userData = localStorage.getItem("SinghRMCAdmin");

    console.log(userData);
    if (!userData) {
      navigate("/login");
      return;
    }
  }, []);
  return (
    <div className="h-[80vh] bg-cust-white flex  items-start">
      <div className="md:mx-auto rounded shadow-xl  w-[100%]  overflow-hidden">
        <div className="h-[100px] bg-[#1f2544]"></div>
        <div className="px-5 py-2 flex flex-col gap-3 pb-6">
          <div className="h-[90px] shadow-md w-[90px] rounded-full border-4 overflow-hidden -mt-14 border-white">
            <img
              src={profile}
              className="w-full h-full rounded-full object-center object-cover"
            />
          </div>
          <div className="">
            <h3 className="text-xl text-slate-900 relative font-bold leading-6">
              {user?.name}
            </h3>
            <p className="text-sm text-gray-600">{user?.email}</p>
            <p className="text-sm text-gray-600">+91 {user?.phoneNumber}</p>
          </div>
          <div className="flex gap-3 flex-wrap">
            <span class="rounded-sm bg-yellow-100 px-3 py-1 text-xs font-medium text-yellow-800">
              {user?.userType}
            </span>
            <span class="rounded-sm bg-green-100 px-3 py-1 text-xs font-medium text-green-800">
              {user?.gender}
            </span>
            <span class="rounded-sm bg-indigo-100 px-3 py-1 text-xs font-medium text-indigo-800">
              {user?.city}
            </span>
            <span class="rounded-sm bg-blue-100 px-3 py-1 text-xs font-medium text-blue-800">
              {user?.state}
            </span>
          </div>
          <p className="text-gray-500 font-medium">
            As an admin, you oversee the platform's core operations, ensuring
            smooth functionality and security. With access to advanced tools and
            settings, your role includes managing users, configuring system
            preferences, monitoring activities, and resolving any issues that
            arise. Your expertise helps maintain efficiency and ensures that the
            system runs optimally for all users.
          </p>
          <h1 className="font-semibold mt-2 text-black ">Address</h1>
          <p className="text-gray-500 font-medium">{user?.address}</p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
