import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import AdminAuthLayout from './AdminAuthLayout';
// import encryptionModule from '../Components/Common/localStorageUtils';
import { useNavigate } from 'react-router-dom';

const AdminLayout = ({ children }) => {
    const navigate = useNavigate();
    // const existUser = encryptionModule.decryptData("User");

    // if (existUser?.userType !== 'admin') {
    //     navigate('/')
    // }

    // useEffect(() => {
    //     if (existUser?.userType !== 'admin') {
    //         navigate('/');
    //     }
    // }, [existUser, navigate])

    return (
        <AdminAuthLayout>
            <Outlet />
        </AdminAuthLayout>
    );
};

export default AdminLayout;
