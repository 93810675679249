import React, { useEffect, useState } from "react";
import banner from "../Assets/Clients/bannerImg.png";
import axios from "axios";
import WhatsappComponent from "../Components/msgBox/WhatsappComponent";

const Galery = () => {
  //   const imagearray = [img1, img2, img3];
  const [imagearray, setimagearray] = useState([]);
  const [loader, setloader] = useState(false);

  const galerydata = () => {
    setloader(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/image/get-all-image`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setimagearray(response.data.data);
        setloader(false);
      })
      .catch((error) => {
        console.log(error);
        setloader(false);
      });
  };
  useEffect(() => {
    galerydata();
  }, []);

  return (
    <>
      <div className="relative">
        <img src={banner} alt="banner" className="w-full" />
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-white text-3xl md:text-5xl font-bold tracking-wider">
            Gallery
          </h1>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 my-8 mx-8">
        {loader ? (
          <div className="h-[20rem] w-[100vw]  flex flex-col justify-center items-center font-medium text-[1.1rem]">
            <div className="loader3"></div>
            <p className="font-normal mt-3 text-black">
              Loading your Page.Please wait...
            </p>
          </div>
        ) : (
          imagearray.map((e, index) => (
            <div
              key={index}
              className="relative overflow-hidden rounded-md h-[18rem] group"
            >
              <div
                className="w-full h-full  bg-cover bg-center duration-300 hover:scale-110"
                style={{ backgroundImage: `url(${e.imgUrl})` }}
              >
                <div className="absolute inset-0 bg-black/30"></div>
              </div>

              <h2 className="text-white text-transform: capitalize text-[1.3rem] font-medium absolute bottom-7 left-6 opacity-0 group-hover:opacity-80 transition-opacity duration-200">
                {e.imgName}
              </h2>
            </div>
          ))
        )}
        <WhatsappComponent />
      </div>
    </>
  );
};

export default Galery;
