import React from "react";
import Careeerdetails from "../Components/Career/Careeerdetails";
import banner from "../Assets/Clients/bannerImg.png";


const Careerpage = () => {
  return (
    <section>
       <div className="relative">
        <img src={banner} alt="banner" className="w-full" />
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-white text-3xl md:text-5xl font-bold tracking-wider">
          Career
          </h1>
        </div>
      </div>
      <div>
        <h3 className="text-[#333333] xl:text-[2.5rem] lg:text-[2.3rem] md:text-[1.5rem] font-medium mt-3 text-[1.2rem] text-center md:mt-4 lg:mt-6">Embrace your career possibilities with Singh RMC</h3>
        <p className="text-[#888888] md:text-[.9rem] lg:w-[90%] xl:w-[85%] text-[.8rem] mt-2 w-[90%] text-center mx-auto md:mt-2 lg:mt-4 mb-4 md:mb-8">
          Want to join the Singh RMC team? Send your updated resume to
          <a href="mailto:Info@singhrmcinfra.com"> Info@singhrmcinfra.com</a> to apply now.
          Singh RMC offers a gateway to exciting career prospects in the dynamic 
          world of Ready Mix concrete. Explore diverse roles, from production to 
          project management, and be part of a team dedicated to delivering 
          excellence in every mix. Your future in the concrete industry awaits 
          at Singh RMC Careers.
        </p>
      </div>
      <div>
       <Careeerdetails/>
      </div>
    </section>
  );
};

export default Careerpage;

