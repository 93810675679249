import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Border from "../../Assets/Border.png";
import Vector from "../../Assets/Vector.png";
import img1 from "../../Assets/one.png";
import img2 from "../../Assets/two.png";
import QCB from "../../Assets/QUICKBUILDERS.webp";
import cvrde from "../../Assets/cvrde.jpeg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "../Home/Testomonial.css";

// import required modules
import { Pagination } from "swiper/modules";

export default function App() {
  return (
    <>
      <div className="xs:hidden sm:block">
        <Swiper
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex justify-center items-center w-[100%] h-[23rem] ">
              <div className=" flex justify-center items-center flex-col relative sm:h-[17rem] lg:h-[20rem] w-[50%] bg-white rounded-lg mx-2 ">
                <img
                  src={Border}
                  className="absolute top-0 left-0 h-[100px] w-[100px] rounded-tl-lg"
                  alt="No Image"
                />
                <img
                  className="absolute top-2 left-4 h-[30px] w-[30px]"
                  src={Vector}
                  alt="No Image"
                />
                <img src={QCB} alt="No Image" />
                <h1 className="font-bold my-2 text-center text-black">
                  Quick Builders
                </h1>

                <p className="text-primaryTextColor tracking-wide text-center px-[2.3rem] my-3 sm:text-[0.9rem] lg:text-[1.2rem]">
                  Singh RMC provided top-notch ready-mix concrete that exceeded
                  our expectations. The consistency and strength of the concrete
                  were perfect, ensuring the structural integrity of our
                  project.
                </p>
              </div>
              <div className="flex justify-center items-center flex-col relative w-[50%] sm:h-[17rem] lg:h-[20rem] bg-white rounded-lg mx-2 ">
                <img
                  src={Border}
                  className="absolute top-0 left-0 h-[100px] w-[100px] rounded-tl-lg"
                  alt="No Image"
                />
                <img
                  className="absolute top-2 left-4 h-[30px] w-[30px]"
                  src={Vector}
                  alt="No Image"
                />
                <img
                  src={cvrde}
                  className="h-[56px] w-[56px] rounded-full"
                  alt="No Image"
                />
                <h1 className="font-bold my-2 text-center text-black">
                  CVRDE Chennai
                </h1>
                <p className="text-primaryTextColor tracking-wide text-center px-[2.3rem] my-3 sm:text-[0.9rem] lg:text-[1.2rem]">
                  Singh RMC is a reliable and professional construction company
                  that I would highly recommend to anyone in need of ready-mix
                  concrete and other construction services.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center items-center w-[100%] h-[25rem] ">
              <div className=" flex justify-center items-center flex-col relative  w-[50%] sm:h-[17rem] lg:h-[20rem] bg-white rounded-lg mx-2 ">
                <img
                  src={Border}
                  className="absolute top-0 left-0 h-[100px] w-[100px] rounded-tl-lg"
                  alt="No Image"
                />
                <img
                  className="absolute top-2 left-4 h-[30px] w-[30px]"
                  src={Vector}
                  alt="No Image"
                />
                <img src={QCB} alt="No Image" />
                <h1 className="font-bold my-2 text-center text-black">
                  Quick Builders
                </h1>

                <p className="text-primaryTextColor tracking-wide text-center px-[2.3rem] my-3 sm:text-[0.9rem] lg:text-[1.2rem]">
                  Singh RMC provided top-notch ready-mix concrete that exceeded
                  our expectations. The consistency and strength of the concrete
                  were perfect, ensuring the structural integrity of our
                  project.
                </p>
              </div>
              <div className="flex justify-center items-center flex-col relative  w-[50%] sm:h-[17rem] lg:h-[20rem] bg-white rounded-lg mx-2 ">
                <img
                  src={Border}
                  className="absolute top-0 left-0 h-[100px] w-[100px] rounded-tl-lg"
                  alt="No Image"
                />
                <img
                  className="absolute top-2 left-4 h-[30px] w-[30px]"
                  src={Vector}
                  alt="No Image"
                />
                <img
                  src={cvrde}
                  className="h-[56px] w-[56px] rounded-full"
                  alt="No Image"
                />
                <h1 className="font-bold my-2 text-center text-black">
                  CVRDE Chennai
                </h1>
                <p className="text-primaryTextColor tracking-wide text-center px-[2.3rem] my-3 sm:text-[0.9rem] lg:text-[1.2rem]">
                  Singh RMC is a reliable and professional construction company
                  that I would highly recommend to anyone in need of ready-mix
                  concrete and other construction services.
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="sm:hidden xs:block">
        <Swiper
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className=" flex justify-center items-center flex-col relative h-[20rem] w-[100%] rounded-lg mx-2">
              <img
                src={Border}
                className="absolute top-0 left-0 h-[100px] w-[100px] rounded-tl-lg"
                alt="No Image"
              />
              <img
                className="absolute top-2 left-4 h-[30px] w-[30px]"
                src={Vector}
                alt="No Image"
              />
              <img src={QCB} alt="No Image" />
              <h1 className="font-bold my-2 text-center text-black">
                Quick Builders
              </h1>

              <p className="text-primaryTextColor tracking-wide text-center px-[2.3rem] my-3 text-[0.9rem]">
                Singh RMC provided top-notch ready-mix concrete that exceeded
                our expectations. The consistency and strength of the concrete
                were perfect, ensuring the structural integrity of our project.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center items-center flex-col relative h-[20rem] w-[100%] bg-white rounded-lg mx-2 ">
              <img
                src={Border}
                className="absolute top-0 left-0 h-[100px] w-[100px] rounded-tl-lg"
                alt="No Image"
              />
              <img
                className="absolute top-2 left-4 h-[30px] w-[30px]"
                src={Vector}
                alt="No Image"
              />
              <img
                src={cvrde}
                className="h-[56px] w-[56px] rounded-full"
                alt="No Image"
              />
              <h1 className="font-bold my-2 text-center text-black">
                CVRDE Chennai
              </h1>
              <p className="text-primaryTextColor tracking-wide text-center px-[2.3rem] my-3 text-[0.9rem]">
                Singh RMC is a reliable and professional construction company
                that I would highly recommend to anyone in need of ready-mix
                concrete and other construction services.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=" flex justify-center items-center flex-col relative h-[20rem] w-[100%] bg-white rounded-lg mx-2 ">
              <img
                src={Border}
                className="absolute top-0 left-0 h-[100px] w-[100px] rounded-tl-lg"
                alt="No Image"
              />
              <img
                className="absolute top-2 left-4 h-[30px] w-[30px]"
                src={Vector}
                alt="No Image"
              />
              <img src={QCB} alt="No Image" />
              <h1 className="font-bold my-2 text-center text-black">
                Quick Builders
              </h1>

              <p className="text-primaryTextColor tracking-wide text-center px-[2.3rem] my-3 text-[0.9rem]">
                Singh RMC provided top-notch ready-mix concrete that exceeded
                our expectations. The consistency and strength of the concrete
                were perfect, ensuring the structural integrity of our project.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center items-center flex-col relative h-[20rem] w-[100%] bg-white rounded-lg mx-2">
              <img
                src={Border}
                className="absolute top-0 left-0 h-[100px] w-[100px] rounded-tl-lg"
                alt="No Image"
              />
              <img
                className="absolute top-2 left-4 h-[30px] w-[30px]"
                src={Vector}
                alt="No Image"
              />
              <img
                src={cvrde}
                className="h-[56px] w-[56px] rounded-full"
                alt="No Image"
              />
              <h1 className="font-bold my-2 text-center text-black">
                CVRDE Chennai
              </h1>
              <p className="text-primaryTextColor tracking-wide text-center px-[2.3rem] my-3 text-[0.9rem]">
                Singh RMC is a reliable and professional construction company
                that I would highly recommend to anyone in need of ready-mix
                concrete and other construction services.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
