import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import LWC from "../../Assets/LWC.png";
import { RiEdit2Fill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";
import { IoCloseCircle } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
  modalSet,
  updateModalOpen,
  saveCurrentProductDetails,
  updateCareersModal,
  updateAllCareers,
  updateEditCareersModal,
  updateCurrentCareerDetails,
  updateCareerid,
} from "../../Components/redux/slice/productSlice";
import { productId } from "../../Components/redux/slice/productSlice";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { RiEditFill } from "react-icons/ri";

const AdminCareers = () => {
  const navigate = useNavigate();
  const userData = localStorage.getItem("SinghRMCAdmin");

  console.log(userData);
  if (userData == null) {
    navigate("/login");
  }
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [modal, setModal] = useState(false);
  const [check, setCheck] = useState(false);
  const [allJobs, setAlljobs] = useState([]);
  let data = "";
  console.log(allJobs);
  const newProduct = useSelector((state) => {
    return state.products.check;
  });
  const check2 = useSelector((state) => {
    return state.products.check2;
  });

  const allCareers = useSelector((state) => {
    return state.products.allCareers;
  });

  // const getAllProducts = () => {
  //   let config = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     url: `https://singh-rmc.onrender.com/pgc-api/getallproducts//getProducts`,
  //     headers: {},
  //     data: data,
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       console.log(response.data);
  //       setProducts(response.data.product);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // Get all jobs

  const getAllJobs = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}/careers/getAll`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setAlljobs(response.data.data);
        dispatch(updateAllCareers(response.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteJob = (data) => {
    if (window.confirm("Are you sure , you want to delete the item ?")) {
      let myData = {
        id: data,
      };

      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/careers/delete-job`,
        headers: {
          "Content-Type": "application/json",
        },
        data: myData,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          getAllJobs();
          toast.success("Job Deleted successfully...");
        })
        .catch((error) => {
          console.log(error);
          toast.error("Couldn't delete the job !");
        });
    }
  };

  //Delete product on click
  // const deleteProduct = (id) => {
  //   // let confirmation = confirm(
  //   //   "Are you sure you want to delete this product ?"
  //   // );
  //   if (
  //     window.confirm("Are you sure you want to delete this product ?") == true
  //   ) {
  //     toast("Deteting Please Wait...");
  //     setTimeout(() => {
  //       let config = {
  //         method: "delete",
  //         maxBodyLength: Infinity,
  //         url: `https://singh-rmc.onrender.com/pgc-api/deleteProduct/delete/${id}`,
  //         headers: {},
  //       };

  //       axios
  //         .request(config)
  //         .then((response) => {
  //           console.log(response.data);
  //           toast.success("Deleted Successfully");
  //           const filteredItems = products.filter((eachItem) => {
  //             return eachItem._id != id;
  //           });
  //           setProducts(filteredItems);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           toast.error("Couldn't delete the item!");
  //         });
  //     }, 4000);
  //   }
  // };

  ///
  // const productIndexAndDetails = (item, index) => {
  //   dispatch(saveCurrentProductDetails({ ...item, index }));
  // };

  useEffect(() => {
    let userData = localStorage.getItem("SinghRMCAdmin");
    console.log(userData);
    if (!userData) {
      navigate("/login");
      return;
    }
    getAllJobs();
  }, [newProduct, check2]);

  return (
    <div className="text-black relative  ">
      {modal ? (
        <div className="h-[100vh] w-[100%] flex justify-center items-center bg-[#0f02025b] absolute top-0 ">
          <div className="h-[35rem] w-[35rem] rounded-lg relative bg-white">
            <IoCloseCircle
              size="25"
              onClick={() => {
                setModal(false);
              }}
              className="absolute top-2 right-2"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="flex justify-between items-center mb-3">
        <h1 className="font-bold sm:text-[1.3rem] xs:text-[1.1rem] ">
          Jobs Available
        </h1>
        <button
          onClick={() => {
            dispatch(updateCareersModal(true));
          }}
          className="bg-[#1f2544] rounded-lg w-[8rem] h-[2.6rem] font-semibold text-white"
        >
          Create
        </button>
      </div>

      <div>
        {allCareers?.length == 0 ? (
          <div className="h-[35rem]  flex flex-col justify-center items-center font-medium text-[1.1rem]">
            <div className="loader3"></div>
            <p className="font-normal mt-3">
              Fetching your Products.Please wait...
            </p>
          </div>
        ) : (
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-[#1F2544] dark:text-gray-400">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    SL NO
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Designation
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Job Description
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Posted On
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Location
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Vacancies
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Qualification
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Experience
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {allCareers && allCareers.length > 0 ? (
                  allCareers.map((eachItem, index) => {
                    return (
                      <tr
                        key={index}
                        className="bg-white border-b dark:bg-gray-50 hover:bg-gray-50 dark:hover:bg-gray-50"
                      >
                        <td scope="row" className="px-6 py-4 whitespace-nowrap">
                          {index + 1}
                        </td>
                        <td
                          scope="row"
                          className="px-6 py-4 whitespace-nowrap capitalize"
                        >
                          {eachItem?.jobPosition.substring(0, 20)}...
                        </td>
                        <td className="px-6 py-4">
                          {eachItem?.jobDescription?.substring(0, 17)}...
                        </td>
                        <td className="px-6 py-4">
                          {eachItem?.posted?.slice(0, 10)}
                        </td>
                        <td className="px-6 py-4">{eachItem?.location}</td>
                        <td className="px-6 py-4">{eachItem?.vacancies}</td>
                        <td className="px-6 py-4">
                          {eachItem?.qualification.substring(0, 17)}
                        </td>
                        <td className="px-6 py-4">{eachItem?.experience}</td>
                        <td className="flex items-center px-6 py-4 gap-3">
                          <RiEditFill
                            onClick={() => {
                              dispatch(updateEditCareersModal(true));
                              dispatch(updateCurrentCareerDetails(eachItem));
                              dispatch(updateCareerid(eachItem._id));
                            }}
                            size={25}
                            color="#1F2544"
                          />
                          <MdDelete
                            onClick={() => {
                              deleteJob(eachItem._id);
                            }}
                            size={25}
                            color="red"
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="9"
                      className="text-center py-4 font-semibold text-lg"
                    >
                      No Jobs Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminCareers;
