import React from "react";
import imageBulb from "../../Assets/imageBulb.png";
import imageTarget from "../../Assets/imageTarget.png";

const OurValues = () => {
  return (
    <div className="bg-[#f3f3f3] ">
      <div className="h-[13rem] xs:h-[15rem] sm:h-[13rem] ">
        <h6 className="text-center pt-2 py-1 text-primaryColor">Services</h6>
        <h1 className="text-center pt-1 py-2 font-bold text-4xl text-primaryTextColor">
          Our Values
        </h1>
        <p className="text-center py-2 w-[29.8rem] tracking-wide  sm:w-[30rem] lg:w-[33rem] xl:w-[43rem] mx-auto text-secondaryTextColor xs:w-[18rem]">
          Singh RMC values quality, customer satisfaction, safety, innovation,
          sustainability, integrity, reliability, environmental responsibility,
          teamwork, and ethical business practices.
        </p>
      </div>
      <div className=" flex justify-center md:flex-nowrap xs:flex-wrap sm:px-[2.2rem] xs:px-[0.9rem] py-2 gap-5">
        <div className="md:px-[2.2rem] xs:px-[0.9rem] py-[2rem] flex flex-col  items-center gap-4 md:h-auto md:w-[50%] xs:w-[100%] xs:h-auto rounded-lg m-2 bg-[#ffffff] text-[black]">
          <img src={imageTarget} alt="No Image" />
          <h1 className="sm:text-3xl xs:text-[1.2rem] font-bold">
            Our Mission
          </h1>
          <h3 className="sm:text-md tracking-wide xs:text-[0.9rem] font-bold text-center">
            Forging excellence in concrete solutions. Building and deploying
            Duramix innovatively
          </h3>
          <p className="text-[black] tracking-wide text-center sm:text-[1rem] xs:text-[0.9rem]">
            At SINGH RMC, our mission is to forge a path of excellence in the
            world of concrete solutions. We operate with a relentless commitment
            to delivering innovative, sustainable and durable products to shape
            the future of construction.
          </p>
        </div>
        <div className="md:px-[2rem] xs:px-[0.9rem] py-[2rem] flex flex-col  items-center gap-4 md:h-auto md:w-[50%] xs:w-[100%] xs:h-auto rounded-lg m-2 bg-[#ffffff] text-[black]">
          <img src={imageBulb} alt="No Image" />
          <h1 className="sm:text-3xl xs:text-[1.2rem] font-bold">Our Vision</h1>
          <h3 className="sm:text-md xs:text-[0.9rem] font-bold text-center tracking-wide">
            Building tomorrow's foundations with durability.
          </h3>
          <p className="text-[black] text-center sm:text-[1rem] xs:text-[0.9rem] tracking-wide">
            We've built SINGH RMC responsibly from the start, incorporating
            safety measures and working with our partners which ensures
            durability, sustainability, and innovation. Our vision is, fostering
            a future of resilience and progress
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurValues;
