import React from "react";
import section from "../../Assets/Section.png";
import fact1 from "../../Assets/fact-1.png";
import fact2 from "../../Assets/fact-2.png";
import fact3 from "../../Assets/fact-3.png";
import fact4 from "../../Assets/fact-4.png";
import CountUp from "react-countup";

const Achievement = () => {
  const data = [
    { count: 20, img: fact1, label: "Team Members" },
    { count: 15, img: fact2, label: "Projects Done" },
    { count: 21, img: fact3, label: "Years of Experience" },
    { count: 18, img: fact4, label: "Happy Customers" },
  ];

  return (
    <div
      style={{ backgroundImage: `url(${section})` }}
      className=" bg-cover bg-center bg-no-repeat md:p-[2.5rem] xs:p-[0.5rem]  "
    >
      <div className="h-[19rem] lg:h-[15rem] md:h-[15rem] ">
        <h6 className="text-center py-3 text-primaryColor">Services</h6>
        <h1 className="text-center py-3 font-bold text-4xl text-white">
          Our Numbers Speak for itself
        </h1>
        <p className=" sm:w-[30rem] lg:w-[33rem] xl:w-[43rem] xs:w-[18rem] text-center py-3 w-[29rem] mx-auto text-secondaryTextColor">
          We have successfully delivered over 1000 projects, ranging from
          residential homes to large-scale commercial developments.
        </p>
      </div>

      <div className=" flex justify-between xs:flex-wrap xs:justify-center items-center pb-[2.5rem]">
        {data.map((each) => {
          return (
            <div className="flex justify-center items-center flex-col relative h-[11rem]    xl:w-[19rem] xs:w-[15rem] xs:mb-8 mb-6 mx-3  md:w-[10rem] md:h-[10rem] lg:w-[13rem] lg:h-[11rem] bg-[rgba(5, 4, 4, 0)]  border-2 border-primaryColor rounded-sm">
              <div
                style={{ backgroundImage: `url(${section})` }}
                className="bg-cover bg-center bg-no-repeat h-[100px] w-[100px] xs:h-[50px]   xs:w-[50px] xs:ml-[-25px] xs:top-[-24px] sm:top-[-43px] md:w-[70px] md:h-[70px] lg:h-[81px] lg:w-[81px] rounded-full  absolute top-[-45px] left-[50%] ml-[-50px] md:ml-[-35px] flex justify-center items-center"
              >
                <img
                  className="h-[50px] w-[50px] xs:h-[37px] xs:w-[37px]"
                  src={each.img}
                  alt="No Image"
                />
              </div>
              <h1 className="text-4xl text-primaryColor font-bold mb-3 md:text-[2rem]">
                <CountUp end={each.count} duration={10} />+
              </h1>

              <p className="text-white font-semibold text-xl md:text-[1rem] xs:text-[0.9rem]">
                {each.label}
              </p>
            </div>
          );
        })}

        {/* <div className="flex justify-center items-center flex-col relative h-[11rem]    xl:w-[19rem] xs:w-[15rem] xs:mb-8 mx-3  md:w-[10rem] md:h-[10rem] lg:w-[13rem] lg:h-[11rem] bg-[rgba(5, 4, 4, 0)]  border-2 border-primaryColor rounded-sm">
          <div
            style={{ backgroundImage: `url(${section})` }}
            className="bg-cover bg-center bg-no-repeat h-[100px] w-[100px] xs:h-[50px]   xs:w-[50px] xs:ml-[-25px] xs:top-[-24px] sm:top-[-43px] md:w-[70px] md:h-[70px] lg:h-[81px] lg:w-[81px] rounded-full  absolute top-[-45px] left-[50%] ml-[-50px] md:ml-[-35px] flex justify-center items-center"
          >
            <img
              className="h-[50px] w-[50px] xs:h-[37px] xs:w-[37px]"
              src={fact2}
              alt="No Image"
            />
          </div>
          <h1 className="text-4xl text-primaryColor font-bold mb-3 md:text-[2rem]">
            341
          </h1>
          <p className="text-white font-semibold text-xl md:text-[1rem] xs:text-[0.9rem] ">
            Projects Done
          </p>
        </div>
        <div className="flex justify-center items-center flex-col relative h-[11rem]    xl:w-[19rem] xs:w-[15rem]  xs:mb-8  mx-3  md:w-[10rem] md:h-[10rem] lg:w-[13rem] lg:h-[11rem] bg-[rgba(5, 4, 4, 0)]  border-2 border-primaryColor rounded-sm">
          <div
            style={{ backgroundImage: `url(${section})` }}
            className="bg-cover bg-center bg-no-repeat h-[100px] w-[100px] xs:h-[50px] xs:w-[50px]   xs:ml-[-25px] xs:top-[-24px] sm:top-[-43px] md:w-[70px] md:h-[70px] lg:h-[81px] lg:w-[81px] rounded-full  absolute top-[-45px] left-[50%] ml-[-50px] md:ml-[-35px] flex justify-center items-center"
          >
            <img
              className="h-[50px] w-[50px] xs:h-[37px] xs:w-[37px]"
              src={fact3}
              alt="No Image"
            />
          </div>
          <h1 className="text-4xl text-primaryColor font-bold mb-3 md:text-[2rem] ">
            232
          </h1>
          <p className="text-white font-semibold text-xl md:text-[1rem] xs:text-[0.9rem]">
            New Equipments
          </p>
        </div>
        <div className="flex justify-center items-center flex-col relative h-[11rem]   xl:w-[19rem] xs:w-[15rem]  mb-8 mx-3  md:w-[10rem] md:h-[10rem] lg:w-[13rem] lg:h-[11rem] bg-[rgba(5, 4, 4, 0)]  border-2 border-primaryColor rounded-sm">
          <div
            style={{ backgroundImage: `url(${section})` }}
            className="bg-cover bg-center bg-no-repeat h-[100px] w-[100px] xs:h-[50px] xs:w-[50px] xs:ml-[-25px] xs:top-[-24px] sm:top-[-43px] md:w-[70px] md:h-[70px] lg:h-[81px] lg:w-[81px] rounded-full  absolute top-[-45px] left-[50%] ml-[-50px] md:ml-[-35px] flex justify-center items-center"
          >
            <img
              className="h-[50px] w-[50px] xs:h-[37px] xs:w-[37px]"
              src={fact4}
              alt="No Image"
            />
          </div>
          <h1 className="text-4xl text-primaryColor font-bold mb-3 md:text-[2rem]">
            940
          </h1>
          <p className="text-white font-semibold text-xl md:text-[1rem] xs:text-[0.9rem]">
            Happy Customers
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Achievement;
