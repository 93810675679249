import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SCC from "../../Assets/SCC.png";
import SFRC from "../../Assets/SFRC.png";
import LWC from "../../Assets/LWC.png";
import HSC from "../../Assets/HSC.png";
import DLCPQC from "../../Assets/DLC&PQC.png";
import GRVCUT from "../../Assets/Groove&Cutting.png";
import { RiEdit2Fill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";
import {
  serviceModalSet,
  productId,
  saveCurrentProductDetails,
  editServiceModal,
} from "../../Components/redux/slice/productSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

const OurServices = () => {
  const navigate = useNavigate();

  const userData = localStorage.getItem("SinghRMCAdmin");

  console.log(userData);
  if (userData == null) {
    navigate("/login");
  }

  const dispatch = useDispatch();
  const [serviceData, setServiceData] = useState([]);
  const [check, setCheck] = useState(false);
  //   const randomArr = [1, 2, 3, 4, 5, 6];

  const newProduct = useSelector((state) => {
    return state.products.check;
  });
  const check2 = useSelector((state) => {
    return state.products.check2;
  });

  const arrOfImgs = [SCC, SFRC, LWC, HSC, DLCPQC, GRVCUT];

  const getAllServices = () => {
    setCheck(true);

    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://singh-rmc.onrender.com/pgc-api/service/getallservice`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data.allServices);
        setServiceData(response.data.allServices);
        setCheck(false);
      })
      .catch((error) => {
        console.log(error);
        setCheck(false);
      });
  };

  ///delete services...
  const deleteProduct = (id) => {
    if (window.confirm("Are you sure, you want to remove this service ?")) {
      toast("Deleting Please Wait...");
      setTimeout(() => {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `https://singh-rmc.onrender.com/pgc-api/service/deleteservice/${id}`,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            console.log(response.data);
            toast.success("Deleted Successfully");
            const filteredItems = serviceData.filter((eachItem) => {
              return eachItem._id != id;
            });
            setServiceData(filteredItems);
          })
          .catch((error) => {
            console.log(error);
            toast.error("Couldn't delete the item!");
          });
      }, 4000);
    }
  };

  const productIndexAndDetails = (item, index) => {
    dispatch(saveCurrentProductDetails({ ...item, index }));
  };

  //   const navigateToProductDetails = (id) => {
  //     navigate(`/product/details/${id}`);
  //   };

  useEffect(() => {
    let userData = localStorage.getItem("SinghRMCAdmin");
    console.log(userData);
    if (!userData) {
      navigate("/login");
      return;
    }
    getAllServices();
  }, [newProduct, check2]);

  return (
    <div className="">
      <div className="flex justify-between items-center mb-3">
        <h1 className="font-bold text-black  sm:text-[1.3rem] xs:text-[1.1rem]">
          Our Services
        </h1>

        <button
          onClick={() => {
            dispatch(serviceModalSet(true));
          }}
          className="bg-[#1f2544] rounded-lg w-[8rem] h-[2.6rem] font-semibold text-white"
        >
          Add Service
        </button>
      </div>
      {/* <div className="h-[13rem] xs:h-[19rem] sm:h-[13rem] ">
        {/* <h6 className="text-center py-3 text-primaryColor">Services</h6> */}
      {/* <h1 className="text-center py-3 font-bold text-4xl text-primaryTextColor">
          Our Services
        </h1>
        <p className="text-center py-3 w-[29.8rem]  sm:w-[30rem] lg:w-[33rem] xl:w-[43rem] mx-auto text-secondaryTextColor xs:w-auto">
          Our construction company specializes in building and infrastructure
          projects, providing services such as design, engineering, and project
          management. They handle residential, commercial, and industrial
          constructions, ensuring quality, safety, and compliance with
          regulations throughout the project lifecycle.
        </p> */}
      {/* </div> */}
      <div>
        {check ? (
          <div className="h-[35rem] flex flex-col justify-center items-center">
            <div className="loader3"></div>
            <p className="font-normal mt-3 text-black">
              Fetching your services.Please wait...
            </p>
          </div>
        ) : (
          <motion.div className="text-black flex flex-wrap justify-center xl:justify-between">
            {serviceData && serviceData.length > 0 ? (
              serviceData.map((each, index) => {
                return (
                  <motion.div
                    key={index}
                    initial={{ scale: 1 }}
                    whileHover={{
                      scale: 1.05,
                      transition: { duration: 0.2 },
                    }}
                    className="w-[32.6rem] xl:w-[49%] bg-white rounded-lg shadow-md p-3 my-2 flex sm:flex-row xs:flex-col"
                  >
                    <img
                      src={each?.ImgUrls?.[0]}
                      className="sm:w-[55%] xs:w-[100%] mr-[10px] rounded-lg"
                      alt="No Image"
                    />
                    <div className="flex flex-col justify-between">
                      <div>
                        <h1 className="font-bold my-2">{each.servicesName}</h1>
                        <p className="mt-2 xl:text-[0.9rem] text-secondaryTextColor">
                          {each.description.substring(0, 100)}...{" "}
                        </p>
                      </div>
                      <div className="flex items-center justify-end">
                        <button className="mr-3">
                          <RiEdit2Fill
                            onClick={() => {
                              dispatch(editServiceModal(true));
                              dispatch(productId(each._id));
                              productIndexAndDetails(each, index);
                            }}
                            size={25}
                            color="#1f2544"
                          />
                        </button>
                        <button>
                          <MdDelete
                            onClick={() => {
                              deleteProduct(each._id);
                            }}
                            size={25}
                            color="red"
                          />
                        </button>
                      </div>
                    </div>
                  </motion.div>
                );
              })
            ) : (
              <div className="h-[20rem]  w-full flex justify-center items-center text-gray-500 text-lg font-semibold mt-10">
                No Servicess Found !
              </div>
            )}
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default OurServices;
