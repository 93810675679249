import React from "react";
import { IoCloseCircle } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
  updateModalOpen,
  saveCurrentProductDetails,
  modalSet,
} from "../redux/slice/productSlice";
import { useForm } from "react-hook-form";
import axios from "axios";
import { check } from "../redux/slice/productSlice";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useState } from "react";
import { updateCheck } from "../redux/slice/productSlice";
import { RxCross2 } from "react-icons/rx";
import { MdAddCircle } from "react-icons/md";
const UpdateProductModal = () => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [currentProduct, setCurrentProduct] = useState("");
  const [editItemIndex, setEditItemIndex] = useState("");
  const [load, setLoad] = useState(false);
  const [keyBenifits, setKeybenefits] = useState("");
  const [keyBenifitsArr, setKeybenefitsArr] = useState([]);
  const [Uses, setUses] = useState("");
  const [UsesArr, setUsesArr] = useState([]);

  const currentProductData = useSelector((state) => {
    return state.products.currentProductDetails;
  });

  const check2 = useSelector((state) => {
    return state.products.check2;
  });
  const productId = useSelector((state) => {
    return state.products.id;
  });
  console.log(productId);
  const handleProductName = (value) => {
    dispatch(
      saveCurrentProductDetails((prev) => {
        return { ...prev, productName: value };
      })
    );
  };
  const handleDescription = (value) => {
    dispatch(
      saveCurrentProductDetails((prev) => {
        return { ...prev, productName: value };
      })
    );
  };
  const handleKeyBenifits = (value) => {
    dispatch(
      saveCurrentProductDetails((prev) => {
        return { ...prev, productName: value };
      })
    );
  };
  const handleParagraph1 = (value) => {
    dispatch(
      saveCurrentProductDetails((prev) => {
        return { ...prev, productName: value };
      })
    );
  };
  const handleParagraphSecond = (value) => {
    dispatch(
      saveCurrentProductDetails((prev) => {
        return { ...prev, productName: value };
      })
    );
  };
  const handleWhereToUse = (value) => {
    dispatch(
      saveCurrentProductDetails((prev) => {
        return { ...prev, productName: value };
      })
    );
  };

  const cloudName = "dnw1ttr02";
  const upload_preset = "wjs1zyo5";
  const updateProduct = (Data) => {
    setLoad(true);

    const imgObj = [
      Data.file1[0],
      Data.file2[0],
      Data.file3[0],
      Data.file4[0],
      Data.file5[0],
    ];
    console.log(imgObj);

    const checkImg = imgObj.filter((eachImg) => {
      return eachImg != undefined;
    });

    console.log("checking", checkImg);

    if (checkImg.length > 0) {
      let newArr = [];
      checkImg.forEach((image) => {
        const file = image;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", upload_preset);

        axios
          .post(
            `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
            formData
          )
          .then((response) => {
            console.log(response.data);
            newArr.push(response.data.secure_url);
            if (newArr.length == checkImg.length) {
              let productData = {
                ...Data,
                ImgUrls: newArr,
                keybenifits: keyBenifitsArr,
                whereToUse: UsesArr,
              };

              console.log(productData);
              let config = {
                method: "put",
                maxBodyLength: Infinity,
                url: `https://singh-rmc.onrender.com/pgc-api/updateProduct/update/${productId}`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: productData,
              };

              axios
                .request(config)
                .then((response) => {
                  console.log(response.data);
                  toast.success(response.data.massage);
                  setLoad(false);
                  dispatch(updateModalOpen(false));
                  if (check2 == false) {
                    dispatch(updateCheck(true));
                  } else {
                    dispatch(updateCheck(false));
                  }
                  return;
                })
                .catch((error) => {
                  console.log(error);
                  toast.error(error.response.data.massage);
                  setLoad(false);
                  dispatch(updateModalOpen(false));
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } else {
      console.log("***********************");
      let productData;

      productData = {
        ...Data,
        keybenifits: keyBenifitsArr,
        whereToUse: UsesArr,
      };

      // if images are not present
      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `https://singh-rmc.onrender.com/pgc-api/updateProduct/update/${productId}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: productData,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);

          toast.success(response.data.massage);
          dispatch(check(response.data.Product));
          setLoad(false);
          dispatch(updateModalOpen(false));
          if (check2 == false) {
            dispatch(updateCheck(true));
          } else {
            dispatch(updateCheck(false));
          }
          return;
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.massage);
          setLoad(false);
          dispatch(updateModalOpen(false));
        });
    }
  };

  const createKeybenfitsArr = () => {
    if (keyBenifitsArr.includes(keyBenifits)) {
      toast.error("You have already added this item.");
      return;
    }
    const newArr = [...keyBenifitsArr];
    newArr.push(keyBenifits);
    setKeybenefitsArr(newArr);
    setKeybenefits("");
  };
  const createUses = () => {
    if (UsesArr.includes(Uses)) {
      toast.error("You have already added this item.");
      return;
    }
    const newArr = [...UsesArr];
    newArr.push(Uses);
    setUsesArr(newArr);
    setUses("");
  };

  const removeKeyBenifiits = (each) => {
    const filterArr = keyBenifitsArr.filter((item) => {
      return each != item;
    });
    setKeybenefitsArr(filterArr);
  };
  const removeUses = (each) => {
    const filterArr = UsesArr.filter((item) => {
      return each != item;
    });
    setUsesArr(filterArr);
  };

  return (
    <div className="h-[100vh] w-[100%] flex justify-center items-center z-50 bg-[#0f02025b] absolute top-0 ">
      <div
        id="careers"
        className="h-[35rem] sm:w-[35rem] xs:w-[20rem] rounded-lg relative bg-white p-[1.5rem] overflow-y-scroll"
      >
        <IoCloseCircle
          size="25"
          color="black"
          onClick={() => {
            dispatch(updateModalOpen(false));
          }}
          className="absolute top-2 right-2"
        />

        <h1 className="text-center font-medium text-[1.5rem] text-black mb-5">
          Update Product
        </h1>
        {load ? (
          <div className="h-[100%] w-[100%]  flex flex-col justify-center items-center">
            <div class="loader1"></div>
            <p className="my-1 text-center text-black">
              Updating your product. Kindly wait....
            </p>
          </div>
        ) : (
          <div className="">
            <form
              onSubmit={handleSubmit(updateProduct)}
              action=""
              className="text-black"
            >
              <label
                className="font-semibold text-[1rem]"
                htmlFor="productName"
              >
                Product Name
              </label>
              <br />
              <input
                className="p-3 border my-2 border-blue-500 h-[4rem] rounded-lg w-[100%]"
                type="text"
                name="productName"
                placeholder="Enter Your Product Name"
                {...register("productName")}
                onChange={(e) => {
                  handleProductName(e.target.value);
                }}
                value={currentProductData.productName}
              />
              <br />
              <label
                className="font-semibold text-[1rem]"
                htmlFor="description"
              >
                Description
              </label>
              <br />
              <textarea
                className=" p-3 border my-2 border-blue-500 h-[8rem] rounded-lg w-[100%]"
                type="text"
                name="description"
                placeholder="Write a description her"
                {...register("description")}
                onChange={(e) => {
                  handleDescription(e.target.value);
                }}
                value={currentProductData.description}
              />
              <br />
              <label
                className="font-semibold text-[1rem]"
                htmlFor="Keybenifits"
              >
                Key Benifits
              </label>
              <br />
              {/* <input
                className="p-3 border my-2 border-blue-500 h-[4rem] rounded-lg w-[100%]"
                type="text"
                name="keybenifits"
                placeholder="Write minimum 1 key benifit here."
                {...register("keybenifits")}
                onChange={(e) => {
                  handleKeyBenifits(e.target.value);
                }}
                value={currentProductData.keybenifits}
              /> */}
              <div className="flex items-center justify-center">
                <input
                  className="p-3 border my-2 border-blue-500 h-[3rem] mr-2 rounded-lg w-[100%]"
                  type="text"
                  name="keybenifits"
                  placeholder="Write minimum 1 key benifit here."
                  value={keyBenifits}
                  onChange={(e) => {
                    setKeybenefits(e.target.value);
                  }}
                  // {...register("keybenifits")}
                />
                <MdAddCircle
                  onClick={createKeybenfitsArr}
                  color="1f2544"
                  size={30}
                />
              </div>
              <div className="flex flex-wrap">
                {keyBenifitsArr?.map((each) => {
                  return (
                    <div className="flex bg-slate-300 rounded-full  justify-center items-center px-3 m-[0.1rem]">
                      <p className="text-black  mr-2 text-center">
                        {each.substring(0, 50)}
                      </p>
                      <RxCross2
                        onClick={() => {
                          removeKeyBenifiits(each);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <br />
              <label className="font-semibold text-[1rem]" htmlFor="paragraph1">
                Paragraph1
              </label>
              <br />
              <textarea
                className="p-3 border my-2 border-blue-500 h-[8rem] rounded-lg w-[100%]"
                type="text"
                name="paragaraph1"
                placeholder="Write some more details about the product here."
                {...register("paragraph1")}
                onChange={(e) => {
                  handleParagraph1(e.target.value);
                }}
                value={currentProductData.paragraph1}
              />
              <br />
              <label
                className="font-semibold text-[1rem]"
                htmlFor="paragraphSecond"
              >
                Paragraph2
              </label>
              <br />
              <textarea
                className="p-3 border my-2 border-blue-500 h-[8rem] rounded-lg w-[100%]"
                type="text"
                name="paragraphSecond"
                placeholder="Second Paragraph about the product."
                {...register("paragraphSecond")}
                onChange={(e) => {
                  handleParagraphSecond(e.target.value);
                }}
                value={currentProductData.paragraphSecond}
              />
              <br />
              <label
                className="font-semibold text-[1rem]"
                htmlFor="Application"
              >
                Where To Use
              </label>
              <br />
              {/* <input
                className="p-3 border my-2 border-blue-500 h-[4rem] rounded-lg w-[100%]"
                type="text"
                name="whereToUse"
                placeholder="Write some application of the product"
                {...register("whereToUse")}
               
                onChange={(e) => {
                  handleWhereToUse(e.target.value);
                }}
                value={currentProductData.whereToUse}
              /> */}
              <div className="flex items-center justify-center">
                <input
                  className="p-3 border my-2 border-blue-500 h-[3rem] mr-2 rounded-lg w-[100%]"
                  type="text"
                  name="keybenifits"
                  placeholder="Write minimum 1 uses here."
                  value={Uses}
                  onChange={(e) => {
                    setUses(e.target.value);
                  }}
                />
                <MdAddCircle onClick={createUses} color="1f2544" size={30} />
              </div>
              <div className="flex flex-wrap">
                {UsesArr?.map((each) => {
                  return (
                    <div className="m-1 flex bg-slate-300 rounded-full  justify-center items-center px-3 mx-[0.1rem]">
                      <p className="text-black mr-2 text-center">
                        {each.substring(0, 50)}
                      </p>
                      <RxCross2
                        onClick={() => {
                          removeUses(each);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <br />

              {/* img uPLOPAD SECTION */}
              <div className=" flex md:flex-row xs:flex-col">
                <div className="md:w-[50%] xs:w-[100%] mr-3">
                  <label className="font-medium" htmlFor="file1">
                    Image 1
                  </label>
                  <input
                    name="file1"
                    type="file"
                    {...register("file1")}
                    placeholder="Upload Your Image here."
                    className="my-2 p-3 text border h-[3rem] border-blue-500 rounded-lg w-[100%]"
                  />
                </div>
                <div className="md:w-[50%] xs:w-[100%]">
                  <label className="font-medium" htmlFor="file2">
                    Image 2
                  </label>
                  <input
                    name="file2"
                    type="file"
                    {...register("file2")}
                    placeholder="Upload Your Image here."
                    className="my-2 p-3 text border h-[3rem] border-blue-500 rounded-lg w-[100%]"
                  />
                </div>
              </div>

              {/* section 5 img upload */}
              <div className=" flex md:flex-row xs:flex-col">
                <div className="md:w-[50%] xs:w-[100%] mr-3">
                  <label className="font-medium" htmlFor="file3">
                    Image 3
                  </label>
                  <input
                    type="file"
                    name="file3"
                    {...register("file3")}
                    placeholder="Upload Your Image here."
                    className="my-2 p-3 text border h-[3rem] border-blue-500 rounded-lg w-[100%]"
                  />
                </div>
                <div className="md:w-[50%] xs:w-[100%]">
                  <label className="font-medium" htmlFor="file4">
                    Image 3
                  </label>
                  <input
                    type="file"
                    name="file4"
                    {...register("file4")}
                    placeholder="Upload Your Image here."
                    className="my-2 p-3 text border h-[3rem] border-blue-500 rounded-lg w-[100%]"
                  />
                </div>
              </div>
              {/* section 6 img upload */}

              <div className="md:w-[50%] xs:w-[100%] mr-3">
                <label className="font-medium" htmlFor="file5">
                  Image 5
                </label>
                <input
                  type="file"
                  name="file5"
                  {...register("file5")}
                  placeholder="Upload Your Image here."
                  className="my-2 p-3 text border h-[3rem] border-blue-500 rounded-lg w-[100%]"
                />
              </div>

              <button className="h-[2.5rem] w-[6.5rem] rounded-lg bg-[#1f2544] text-white font-semibold">
                Update
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateProductModal;
