import React from "react";

const Services = () => {
  return (
    <div className="h-[13rem] xs:h-[15rem] sm:h-[13rem] lg:mt-4">
      <h6 className="text-center py-1 pt-4 text-primaryColor">Services</h6>
      <h1 className="text-center py-1 font-bold text-4xl text-primaryTextColor">
        What We Deliver
      </h1>
      <p className="text-center py-3 tracking-wide w-[29.8rem]  sm:w-[30rem] lg:w-[33rem] xl:w-[43rem] mx-auto text-secondaryTextColor xs:w-[18rem]">
        Singh RMC offers a seamless design-build approach, combining
        architectural design and construction into a single streamlined process,
        reducing costs and project timelines.
      </p>
    </div>
  );
};

export default Services;
