import React, { useState, useRef } from "react";
import banner from "../Assets/Clients/bannerImg.png";
import WhatsappComponent from "../Components/msgBox/WhatsappComponent";

const Contact = () => {
  return (
    <div>
      <div className="relative">
        <img src={banner} alt="banner" className="w-full" />
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-3xl md:text-5xl font-bold tracking-wider text-white">
            Contact Us
          </h1>
        </div>
      </div>
      <div className="xl:w-[80rem] mx-auto ">
        <div className=" sm:px-10 md:px-20 xl:flex xl:items-center xl:justify-center ">
          <div
            data-aos="flip-down"
            className="flex flex-col sm:flex-row xl:flex-col gap-6 my-5 py-5 px-4"
          >
            <div className="flex flex-col gap-4 w-full md:w-1/3 xl:w-[30rem] ">
              <h1 className="text-xl font-semibold tracking-wider text-themeBlack text-[#28b40e]">
                CONTACT US
              </h1>
              <h3 className="text-md font-bold tracking-wider text-themeBlack text-black">
                Singh RMC Infra
              </h3>
              <div className="text-sm text-justify text-themeBlack tracking-wider opacity-90 leading-6 text-black">
                <p>WhatsApp: +91-9776170355</p>
                <p>Mobile: +91-9437710574</p>
                <p>Info@singhrmcinfra.com</p>
              </div>
            </div>

            <div
              data-aos="flip-down"
              className="flex flex-col gap-4 xl:gap-2 w-full md:w-1/3 xl:w-[30rem]  text-black"
            >
              <h1 className="text-xl font-semibold tracking-wider text-themeBlack text-[#28b40e]">
                OUR ADDRESS
              </h1>
              <h3 className="text-[1rem] font-bold tracking-wider text-themeBlack">
                Singh RMC Infra
              </h3>
              <p className="xl:hidden text-themeBlack tracking-wider opacity-90 leading-7 text-sm">
                Singh House,
                <br />
                Nimeishapur, Bhagatpur,
                <br />
                Cuttack
              </p>
              <p className="xs:hidden xl:block text-themeBlack tracking-wider opacity-90 leading-7 text-sm">
                Singh House, Nimeishapur, Bhagatpur, Cuttack
              </p>
            </div>

            <div
              data-aos="flip-down"
              className="flex flex-col gap-4 w-full md:w-1/3 xl:w-[30rem]  "
            >
              <h1 className="text-xl font-semibold tracking-wider text-themeBlack text-[#28b40e]">
                We're Here to Help!
              </h1>

              <p className="text-themeBlack tracking-wider opacity-90 leading-5 text-sm text-justify text-black">
                Thank you for reaching out to us! Whether you have a question,
                need assistance, or want to provide feedback, we are here to
                help. Our dedicated team is committed to providing you with the
                best service possible. Please feel free to contact us.
              </p>
            </div>
          </div>
          <div className="mx-auto xs:w-[20rem] lg:h-[38rem]  xs:mt-[2rem] sm:w-[100%] w-[50%] mt-[1.4rem] flex justify-center items-center ">
            <form
              action="https://api.web3forms.com/submit"
              method="POST"
              className="xL:shadow-xl max-h-[40rem] md:w-[30rem] xs:w-[18rem] rounded-lg w-[26rem] bg-white p-4"
            >
              <input
                type="hidden"
                name="access_key"
                value="ff19a324-e183-4994-bba5-c35499cd4387"
              ></input>

              <label className="my-2 text-[#28b40e] font-semibold" htmlFor="">
                Your name
              </label>
              <br />
              <input
                name="name"
                required
                placeholder="Enter Your Name."
                className="p-2 h-[3rem] my-2 w-[100%] rounded-lg border-2 text-black"
                type="text"
              />
              <br />

              <label className="my-2 text-[#28b40e] font-semibold" htmlFor="">
                Email adress
              </label>
              <br />
              <input
                name="email"
                required
                placeholder="Enter your email"
                className="p-2 h-[3rem] my-2 w-[100%] rounded-lg border-2 text-black"
                type="text"
              />
              <br />

              <label className="my-2 text-[#28b40e] font-semibold" htmlFor="">
                Massage
              </label>
              <br />
              <textarea
                className="p-3 w-[100%] rounded-lg border-2 resize-none text-black"
                name="massage"
                required
                placeholder="Type your massage below."
                id=""
                cols="50"
                rows="8"
              ></textarea>
              {/* <input
                  className="h-[7rem] my-2 w-[100%] rounded-lg border-2"
                  type="text"
                /> */}
              <br />
              <button className="bg-[#28b40e] text-white font-semibold h-[3rem] w-[6rem] rounded-lg float-right">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
      <WhatsappComponent />
    </div>
  );
};

export default Contact;
