import React from "react";
import bgdash from "../../Assets/bgdash.jpg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { FaUsers, FaSmile, FaConciergeBell } from "react-icons/fa";
import { MdWork, MdShoppingCart } from "react-icons/md";
import { BiTimeFive } from "react-icons/bi";
import { motion } from "framer-motion";
const AdminDashboard = () => {
  const navigate = useNavigate();

  const dashboard_data = [
    {
      id: 1,
      name: "Team Members",
      total_numbers: 20,
      icon: <FaUsers size="35" />,
    },
    {
      id: 2,
      name: "Projects Done",
      total_numbers: 15,
      icon: <MdWork size="35" />,
    },
    {
      id: 3,
      name: "Year Of Experience",
      total_numbers: 21,
      icon: <BiTimeFive size="35" />,
    },
    {
      id: 4,
      name: "Happy Customers",
      total_numbers: 18,
      icon: <FaSmile size="35" />,
    },
    {
      id: 5,
      name: "Total Products",
      total_numbers: 6,
      icon: <MdShoppingCart size="35" />,
    },
    {
      id: 6,
      name: "Total Services",
      total_numbers: 2,
      icon: <FaConciergeBell size="35" />,
    },
  ];

  useEffect(() => {
    let userData = localStorage.getItem("SinghRMCAdmin");

    console.log(userData);
    if (!userData) {
      navigate("/login");
    }
  }, []);

  return (
    <div>
      <div className="h-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
        {dashboard_data.map((each) => {
          return (
            <motion.div
              key={each.id}
              initial={{ scale: 1 }}
              whileHover={{
                scale: 1.05,
                transition: { duration: 0.2 },
              }}
              className="bg-white rounded-lg shadow-xl flex text-black p-4"
            >
              <div className="w-[30%] flex justify-center items-center">
                <div className="text-white w-[50px] sm:w-[60px] h-[50px] sm:h-[60px] rounded-full bg-custBlack flex items-center justify-center">
                  {each.icon}
                </div>
              </div>
              <div className="w-[70%] flex flex-col gap-1 justify-center">
                <h1 className="text-lg sm:text-lg md:text-xl xl:text-2xl font-semibold">
                  {each.name}
                </h1>
                <p className="text-sm tracking-wide sm:text-base md:text-lg xl:text-xl font-semibold">
                  {each.total_numbers}+
                </p>
              </div>
            </motion.div>
          );
        })}
      </div>

      <div
        style={{
          backgroundImage: `url(${bgdash})`,
        }}
        className="relative h-[30rem] bg-no-repeat bg-cover bg-center rounded-lg my-5"
      >
        {/* Dark overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg"></div>

        {/* Centered text */}
        <div className="relative z-10 flex flex-col items-center justify-center h-full">
          <h1 className="text-white  text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold">
            Singh RMC Infra
          </h1>
          {/* <p className="text-[0.8rem] font-semibold my-1 text-white tracking-wide">
            CONCRETE IS OUR LANGUAGE
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
