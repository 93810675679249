import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorPage from "./Common/ErrorPage";
import WithUserLayout from "./Layout/WithUserLayout";
import AdminLayout from "./Layout/AdminLayout";
import { userRoutes, adminRoutes, publicRoutes } from "../src/Routes/AllRoutes";
import { Toaster } from "react-hot-toast";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const App = () => {
  const userType = "admin";

  useEffect(() => {
    AOS.init({
      offset: 120,
      delay: 0,
      duration: 800,
      easing: "ease",
    });
  }, []);
  return (
    <div className="relative">
      <BrowserRouter>
        <Routes>
          {publicRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}

          {adminRoutes.map((route) => (
            <Route key={route.path} element={<AdminLayout />}>
              <Route path={route.path} element={route.element} />
            </Route>
          ))}

          {userRoutes.map((route) => (
            <Route key={route.path} element={<WithUserLayout />}>
              <Route path={route.path} element={route.element} />
            </Route>
          ))}

          {/* <Route path="*" element={<ErrorPage />} /> */}
        </Routes>
        <Toaster></Toaster>
      </BrowserRouter>
    </div>
  );
};

export default App;
