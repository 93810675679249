import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { serviceDetails } from "../Components/redux/slice/productSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import toast from "react-hot-toast";
import WhatsappComponent from "../Components/msgBox/WhatsappComponent";
const ServicesDetails = () => {
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  const details = useSelector((state) => {
    return state.products.serviceDetails;
  });
  console.log(details);

  const getServiceById = () => {
    setLoad(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://singh-rmc.onrender.com/pgc-api/service/getservice/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        dispatch(serviceDetails(response.data.allServices));
        setLoad(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error loading this page !");
        setLoad(false);
      });
  };

  const scrollTop = () => {
    window.scroll(300, 0);
  };

  useEffect(() => {
    getServiceById();
    scrollTop();
  }, [id]);
  return (
    <div>
      {load ? (
        <div className="h-[35rem]  flex flex-col justify-center items-center font-medium text-[1.1rem]">
          <div className="loader3"></div>
          <p className="font-normal mt-3 text-black">
            Loading your Page.Please wait...
          </p>
        </div>
      ) : (
        <div className="text-black">
          <div className="hero lg:h-[30rem]  flex lg:flex-row sm:h-[40rem] xs:h-[25rem] xs:flex-col gap-2">
            <div
              style={{ backgroundImage: `url(${details?.ImgUrls?.[0]})` }}
              className="lg:h-[100%] xs:h-[70%] lg:w-[65%] xs:w-[100%] rounded-b-md bg-cover bg-center bg-no-repeat"
            >
              <div className="h-[100%] w-[100%] bg-[#00000056] flex justify-center items-center">
                <h1 className="xl:text-3xl sm:text-[1.5rem] xs:text-[1rem] text-center  font-semibold text-white">
                  {details.servicesName}
                </h1>
              </div>
            </div>
            <div className="lg:h-[100%] xs:h-[30%] lg:w-[35%] xs:w-[100%] grid lg:grid-cols-1 lg:grid-rows-2 xs:grid-cols-2 xs:grid-rows-1 gap-2  ">
              <div
                style={{ backgroundImage: `url(${details?.ImgUrls?.[1]})` }}
                className="lg:rounded-b-md xs:rounded-md bg-cover bg-center bg-no-repeat"
              ></div>
              <div
                style={{ backgroundImage: `url(${details?.ImgUrls?.[2]})` }}
                className="rounded-md bg-cover bg-center bg-no-repeat"
              ></div>
            </div>
          </div>
          <h1 className="text-center font-bold text-3xl mt-5 mb-2">
            Our Services
          </h1>
          <div className="sm:p-[2.5rem] xs:p-[1.5rem]">
            <h1 className="font-bold text-2xl mb-3">{details.servicesName}</h1>
            <div className="mb-3">
              <h1 className="font-semibold text-[1.4rem] mb-1 leading-7">
                Service Details
              </h1>
              <p className="tracking-wide">{details.servicesDetails}</p>
            </div>
            <div>
              <h1 className="font-semibold text-[1.4rem] mb-1">
                More About The Service
              </h1>
              <p className="leading-7 tracking-wide">{details.description}</p>
            </div>
          </div>
        </div>
      )}
      <WhatsappComponent />
    </div>
  );
};

export default ServicesDetails;
