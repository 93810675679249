import React, { useState } from "react";
import dummyImg from "../../Assets/dummImg.png";
import Services from "../Home/Services";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SCC from "../../Assets/SCC.png";
import SFRC from "../../Assets/SFRC.png";
import LWC from "../../Assets/LWC.png";
import HSC from "../../Assets/HSC.png";
import DLCPQC from "../../Assets/DLC&PQC.png";
import GRVCUT from "../../Assets/Groove&Cutting.png";
import { motion } from "framer-motion";

const OurServices = () => {
  const navigate = useNavigate();
  const [serviceData, setServiceData] = useState([]);
  const [load, setLoad] = useState(false);
  //   const randomArr = [1, 2, 3, 4, 5, 6];

  const arrOfImgs = [SCC, SFRC, LWC, HSC, DLCPQC, GRVCUT];

  const getAllServices = () => {
    setLoad(true);
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://singh-rmc.onrender.com/pgc-api/service/getallservice`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data.allServices);
        setServiceData(response.data.allServices);
        setLoad(false);
      })
      .catch((error) => {
        console.log(error);
        setLoad(false);
      });
  };

  const navigateToServiceDetails = (id) => {
    navigate(`/service/details/${id}`);
  };

  useEffect(() => {
    getAllServices();
  }, []);

  return (
    <div id="Services" className="px-[2.5rem] xs:px-[1.5rem] ">
      <div className="h-[13rem] xs:h-[14rem] xs:mb-4 sm:mb-0 sm:h-[10rem] ">
        {/* <h6 className="text-center py-3 text-primaryColor">Services</h6> */}
        <h1 className="text-center py-3 font-bold text-4xl text-primaryTextColor">
          Our Services
        </h1>
        <p className="text-center xl;py-2 tracking-wide lg:py-3 w-[29.8rem]   lg:w-[45rem] xl:w-[58rem] mx-auto text-secondaryTextColor xs:w-auto">
          Singh RMC is a dynamic construction company specializing in a wide
          range of services to meet the diverse needs of its clients.
          Established in 2005, the company has grown to become a trusted partner
          in the construction industry.
        </p>
      </div>
      {load ? (
        <div className="h-[6rem] flex justify-center items-center">
          <div className="loader3"></div>
        </div>
      ) : (
        <motion.div className="grid grid-cols-1 sm:grid-cols-2 gap-5 text-black my-2">
          {serviceData?.map((each, index) => (
            <motion.div
              key={index}
              initial={{ scale: 1 }}
              whileHover={{
                scale: 1.03,
                transition: { duration: 0.2 },
              }}
              onClick={() => {
                navigateToServiceDetails(each._id);
              }}
              className="bg-white rounded-lg shadow-md p-3 cursor-pointer flex flex-col sm:flex-row"
            >
              <img
                src={each?.ImgUrls?.[0]}
                className="w-full sm:w-[40%] rounded-lg mb-2 sm:mb-0 sm:mr-2"
                alt="No Image"
              />
              <div className="flex flex-col xl:mx-2">
                <h1 className="font-bold my-2 xl:text-[1.3rem]">
                  {each.servicesName}
                </h1>
                <p className="text-secondaryTextColor xl:text-[1.1rem]">
                  {each.description.substring(0, 150)}...
                </p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      )}
    </div>
  );
};

export default OurServices;
