import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import Carousel from "../Components/DetailsPageSlider/Carousel";
import toast from "react-hot-toast";
import WhatsappComponent from "../Components/msgBox/WhatsappComponent";

const MyProductDetails = () => {
  const [data, setData] = useState("");
  const [load, setLoad] = useState(false);
  const { id } = useParams();
  console.log(data);
  console.log("I am id", id);

  const getProductDetails = () => {
    setLoad(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://singh-rmc.onrender.com/pgc-api/allproducts/getproduct/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setData(response.data.product);
        setLoad(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error loading this page !");
        setLoad(false);
      });
  };

  const scrollTop = () => {
    window.scroll(300, 0);
  };

  useEffect(() => {
    getProductDetails();
    scrollTop();
  }, [id]);
  return (
    <div>
      {load ? (
        <div className="h-[35rem]  flex flex-col justify-center items-center font-medium text-[1.1rem]">
          <div className="loader3"></div>
          <p className="font-normal mt-3 text-black">
            Loading your Page.Please wait...
          </p>
        </div>
      ) : (
        <div className="text-white">
          {/* ------1st */}
          <div className=" xs:hidden md:flex ">
            <div className="md:w-[50%]">
              <Carousel details={data?.productName} data={data} />
            </div>
            <div className=" grid md:grid-cols-2 md:grid-rows-2 md:gap-2 md:h-[17rem] lg:h-[20rem] xl:h-[25rem] 2xl:h-[30rem] md:w-[50%] md:mx-[0.5rem]">
              <div className="">
                <img
                  src={data?.ImgUrls?.[1]}
                  className="rounded-b-md h-[100%] w-[100%]"
                  alt=""
                />
              </div>
              <div className="">
                <img
                  src={data?.ImgUrls?.[2]}
                  className="rounded-b-md h-[100%] w-[100%]"
                  alt=""
                />
              </div>
              <div className="">
                <img
                  src={data?.ImgUrls?.[3]}
                  className="rounded-md h-[100%] w-[100%]"
                  alt=""
                />
              </div>
              <div className="">
                <img
                  src={data?.ImgUrls?.[4]}
                  className="rounded-md h-[100%] w-[100%]"
                  alt=""
                />
              </div>
            </div>
          </div>

          {/* ------- 2nd */}
          <div className="xs:block md:hidden ">
            <div className="">
              <Carousel details={data?.productName} data={data} />
            </div>
            <div className="grid grid-cols-2 grid-rows-2 gap-2 h-[18rem] m-2">
              <div className="">
                <img
                  src={data?.ImgUrls?.[1]}
                  className="rounded-md h-[100%] w-[100%]"
                  alt=""
                />
              </div>
              <div className="">
                <img
                  src={data?.ImgUrls?.[2]}
                  className="rounded-md h-[100%] w-[100%]"
                  alt=""
                />
              </div>
              <div className="">
                <img
                  src={data?.ImgUrls?.[3]}
                  className="rounded-md h-[100%] w-[100%]"
                  alt=""
                />
              </div>
              <div className="">
                <img
                  src={data?.ImgUrls?.[4]}
                  className="rounded-md h-[100%] w-[100%]"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="text-[#333333] sm:px-[2.5rem] sm:py-[1.5rem] xs:px-[1.2rem] xs:py-[1rem]">
            <h1 className="font-bold my-5 lg:text-[2.1rem] xs:text-[1.3rem]">
              {data?.productName}
            </h1>
            <p className="text leading-7 tracking-wide"> {data?.description}</p>
            <div className="lg:flex my-3">
              <div className="mr-5">
                <p className="mt-2 mb-1 font-semibold text-[1.1rem]">
                  Key Benifits
                </p>
                {data.keybenifits?.map((each, index) => {
                  return (
                    <p className="my-2">
                      <span className="font-semibold">{index + 1}</span>: {each}
                    </p>
                  );
                })}
              </div>
              <div className="">
                <p className="mt-2 mb-1 font-semibold text-[1.1rem]">
                  Where To Use
                </p>
                {data.whereToUse?.map((each, index) => {
                  return (
                    <p className="my-2">
                      <span className="font-semibold">{index + 1}</span>: {each}
                    </p>
                  );
                })}
              </div>
            </div>
            <h1 className="text-[#333333] text-[1.1rem] font-semibold">
              More Details
            </h1>
            <p className="mb-3 mt-1 leading-7 tracking-wide">{data?.paragraph1}</p>
            <p className="tracking-wide">{data?.paragraphSecond}</p>
          </div>
        </div>
      )}
      <WhatsappComponent />
    </div>
  );
};

export default MyProductDetails;
