import Image1 from "../../Assets/imgslide1.webp";
import Image2 from "../../Assets/imgSlide2.webp";
import Image3 from "../../Assets/imgSlide3.jpeg";
import Image4 from "../../Assets/imgSlide4.jpg";
import Image5 from "../../Assets/imgSlide5.jpg";

import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./Carousel.css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

export default function App({ details, data }) {
  return (
    <>
      <Swiper
        pagination={{
          type: "fraction",
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div
            style={{ backgroundImage: `url(${data?.ImgUrls?.[0]})` }}
            className="   bg-cover bg-center bg-no-repeat h-[20rem] md:h-[17rem] lg:h-[20rem] xl:h-[25rem] 2xl:h-[30rem] w-[100%] "
          >
            <div className="flex justify-center items-center h-[100%] w-[100%] bg-[#05010177]">
              {/* <p className="text-[1.8rem] font-semibold brightness-150">
                {details}
              </p> */}
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div
            style={{ backgroundImage: `url(${data?.ImgUrls?.[1]})` }}
            className="   bg-cover bg-center bg-no-repeat h-[20rem] md:h-[17rem] lg:h-[20rem] xl:h-[25rem]  2xl:h-[30rem] w-[100%] "
          >
            <div className="flex justify-center items-center h-[100%] w-[100%] bg-[#05010177]">
              {/* <p className="text-[1.8rem] font-semibold brightness-150">
                {details}
              </p> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            style={{ backgroundImage: `url(${data?.ImgUrls?.[2]})` }}
            className="   bg-cover bg-center bg-no-repeat h-[20rem] md:h-[17rem] lg:h-[20rem] xl:h-[25rem] 2xl:h-[30rem] w-[100%] "
          >
            <div className="flex justify-center items-center h-[100%] w-[100%] bg-[#05010177]">
              {/* <p className="text-[1.8rem] font-semibold brightness-150">
                {details}
              </p> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            style={{ backgroundImage: `url(${data?.ImgUrls?.[3]})` }}
            className="   bg-cover bg-center bg-no-repeat h-[20rem] md:h-[17rem] lg:h-[20rem] xl:h-[25rem] 2xl:h-[30rem] w-[100%] "
          >
            <div className="flex justify-center items-center h-[100%] w-[100%] bg-[#05010177]">
              {/* <p className="text-[1.8rem] font-semibold brightness-150">
                {details}
              </p> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            style={{ backgroundImage: `url(${data?.ImgUrls?.[4]})` }}
            className="   bg-cover bg-center bg-no-repeat h-[25rem] md:h-[17rem] lg:h-[20rem] xl:h-[25rem] 2xl:h-[30rem] w-[100%] "
          >
            <div className="flex justify-center items-center h-[100%] w-[100%] bg-[#05010177]">
              {/* <p className="text-[1.8rem] font-semibold brightness-150">
                {details}
              </p> */}
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";

// import "./Carousel.css";

// // import required modules
// import { Pagination } from "swiper/modules";

// export default function App({ details }) {
//   return (
//     <>
//       <div className="">
//         <Swiper
//           spaceBetween={30}
//           pagination={{
//             clickable: true,
//           }}
//           modules={[Pagination]}
//           className="mySwiper"
//         >
//           <SwiperSlide>
//             <div
//               style={{ backgroundImage: `url(${Image1})` }}
//               className="   bg-cover bg-center bg-no-repeat h-[25rem] w-[100%] "
//             >
//               <div className="flex justify-center items-center h-[100%] w-[100%] bg-[#05010177]">
//                 <p className="text-[1.8rem] font-semibold brightness-150">
//                   {details}
//                 </p>
//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide>
//             <div
//               style={{ backgroundImage: `url(${Image2})` }}
//               className="  bg-cover bg-center bg-no-repeat h-[25rem] w-[100%] "
//             >
//               <div className="flex justify-center items-center h-[100%] w-[100%] bg-[#05010177]">
//                 <p className="text-[1.8rem] font-semibold brightness-150">
//                   {details}
//                 </p>
//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide>
//             <div
//               style={{ backgroundImage: `url(${Image3})` }}
//               className=" text-[1.5rem] bg-cover  bg-no-repeat h-[25rem] w-[100%] "
//             >
//               <div className="flex justify-center items-center h-[100%] w-[100%] bg-[#05010177]">
//                 <p className="text-[1.8rem] font-semibold brightness-150">
//                   {details}
//                 </p>
//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide>
//             <div
//               style={{ backgroundImage: `url(${Image4})` }}
//               className="text-[1.5rem] bg-cover bg-center bg-no-repeat h-[25rem] w-[100%]"
//             >
//               <div className="flex justify-center items-center h-[100%] w-[100%] bg-[#05010177]">
//                 <p className="text-[1.8rem] font-semibold brightness-150">
//                   {details}
//                 </p>
//               </div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide>
//             <div
//               style={{ backgroundImage: `url(${Image5})` }}
//               className=" text-[1.5rem] bg-cover bg-center bg-no-repeat h-[25rem] w-[100%] "
//             >
//               <div className="flex justify-center items-center h-[100%] w-[100%] bg-[#05010177]">
//                 <p className="text-[1.8rem] font-semibold brightness-150">
//                   {details}
//                 </p>
//               </div>
//             </div>
//           </SwiperSlide>
//         </Swiper>
//       </div>
//     </>
//   );
// }
