import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaStream } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../Assets/Logos/rmcLogo.png";

// import logoText from '../Assets/logoText.png';
import LogoutModal from "../Components/UserProfile/modals/LogoutModal";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaUserCircle } from "react-icons/fa";
import { BsBoxSeamFill } from "react-icons/bs";
import service from ".././Assets/service.png";
import box from ".././Assets/box.png";
import dashboard from ".././Assets/dashboard.png";
import { FaWindowClose } from "react-icons/fa";
import career from ".././Assets/careerpath.png";
import gallery from ".././Assets/gallery.png";

const AdminNavbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const path = useLocation();
  const pathname = path?.pathname;
  const [show, setShow] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const onClose = () => {
    setShow(false);
  };

  return (
    <React.Fragment>
      <LogoutModal show={show} onClose={onClose} />

      <div className="flex h-[100%] lg:w-[80%] w-[100%] items-center lg:justify-end justify-between lg:p-0 px-4">
        <div onClick={toggleDrawer} className="lg:hidden contents">
          <FaStream size={20} color="#4880FF" />
        </div>

        {/* <button
          onClick={() => setShow(true)}
          className="h-10 w-20 rounded-md bg-pink-800 text-white"
        >
          Logout
        </button> */}
        <div className="cursor-pointer group relative inline-block hover:text-white duration-300 tracking-wide font-semibold">
          <div className="flex items-center xl:mr-[2rem] md:mr-[1rem]">
            <FaUserCircle color="black" size={30} className="" />
            <RiArrowDropDownLine color="black" size="25" />
          </div>
          <div className="absolute z-10 top-full left-[-1rem] w-[5rem]   flex flex-col  rounded-b-md opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-all duration-300 transform origin-top scale-y-0 group-hover:scale-y-100">
            <div
              onClick={() => {
                navigate("/admin/profile");
              }}
              className="cursor-pointer px-3 py-2 text-start duration-300 bg-[#344C64] hover:bg-white hover:text-blue-700  rounded-t-lg"
            >
              Profile
            </div>

            <div
              onClick={() => {
                setShow(true);
              }}
              className="cursor-pointer px-3 py-2 text-start duration-300 bg-[#344C64] hover:bg-white hover:text-blue-700  rounded-b-lg"
            >
              Logout
            </div>
          </div>
        </div>

        {/* Mobile Drawer */}
        <AnimatePresence>
          {isDrawerOpen && (
            <motion.div
              initial={{ x: "-100%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "-100%", opacity: 0 }}
              transition={{ ease: "easeInOut", duration: 0.3 }}
              className="lg:hidden fixed top-0 left-0 md:w-[50%] w-[80%] h-full bg-[#EEEDEB] z-50 border-r-2 p-4"
              whileTap={{ cursor: "pointer" }}
              onClick={() => setIsDrawerOpen(false)}
            >
              <div className="flex items-center justify-end">
                <button className="border flex items-center justify-center hover:bg-gray-300 cursor-pointer">
                  <IoMdClose size={20} color="black" />
                </button>
              </div>

              <div className="flex justify-center items-center w-full mb-2 gap-2 bg-[#1F2544]  rounded-md py-2 mt-3">
                <img src={logo} alt="Logo" className="w-28 h-12" />
                {/* <img src={logoText}
                                    alt="Logo"
                                    className="w-36 h-auto" /> */}
              </div>

              <div className="flex flex-col gap-3 tracking-wider mb-1">
                <div
                  onClick={() => {
                    navigate("/admin/dashboard");
                  }}
                  className={`${
                    pathname === "/admin/dashboard"
                      ? "bg-primaryColor text-white font-semibold"
                      : "bg-[#1F2544] text-white hover:translate-x-2 hover:text-primaryColor"
                  } flex cursor-pointer items-center gap-3 p-2 text-lg  text-white rounded transition-transform duration-200`}
                >
                  <img
                    src={dashboard}
                    className="h-[30px] w-[30px]"
                    alt=""
                    srcset=""
                  />{" "}
                  Dashboard
                </div>
              </div>
              <div className="flex flex-col gap-3 tracking-wider mb-1">
                <div
                  onClick={() => {
                    navigate("/admin/Products");
                  }}
                  className={`${
                    pathname === "/admin/Products"
                      ? "bg-primaryColor text-white font-semibold"
                      : "bg-[#1F2544] text-white hover:translate-x-2 hover:text-primaryColor"
                  } flex cursor-pointer items-center gap-3 p-2 text-lg text-white rounded transition-transform duration-200`}
                >
                  <img
                    src={box}
                    className="h-[30px] w-[30px]"
                    alt=""
                    srcset=""
                  />{" "}
                  Products
                </div>
              </div>
              <div className="flex flex-col gap-3 tracking-wider mb-1">
                <div
                  onClick={() => {
                    navigate("/admin/services");
                  }}
                  className={`${
                    pathname === "/admin/services"
                      ? "bg-primaryColor text-white font-semibold"
                      : "bg-[#1F2544] text-white hover:translate-x-2 hover:text-primaryColor"
                  } flex cursor-pointer items-center gap-3 p-2 text-lg  text-white rounded transition-transform duration-200`}
                >
                  <img
                    src={service}
                    alt="No Image"
                    className="h-[30px] w-[30px]"
                    srcset=""
                  />{" "}
                  Services
                </div>
              </div>
              <div className="flex flex-col gap-3 tracking-wider mb-1">
                <div
                  onClick={() => {
                    navigate("/admin/careers");
                  }}
                  className={`${
                    pathname === "/admin/careers"
                      ? "bg-primaryColor text-white font-semibold"
                      : "bg-[#1F2544] text-white hover:bg-gray-200 hover:translate-x-2 font-normal hover:text-primaryColor"
                  } flex cursor-pointer items-center gap-3 p-2 text-lg rounded transition-transform duration-200`}
                >
                  <img
                    src={career}
                    alt="No Image"
                    className="h-[30px] w-[30px]"
                    srcset=""
                  />{" "}
                  Careers
                </div>
              </div>

              <div className="flex flex-col gap-3 tracking-wider ">
                <div
                  onClick={() => {
                    navigate("/admin/gallery");
                  }}
                  className={`${
                    pathname === "/admin/gallery"
                      ? "bg-primaryColor text-white font-semibold"
                      : "bg-[#1F2544] text-white hover:bg-gray-200 hover:translate-x-2 font-normal hover:text-primaryColor"
                  } flex cursor-pointer items-center gap-3 p-2 text-lg rounded transition-transform duration-200`}
                >
                  <img
                    src={gallery}
                    alt="No Image"
                    className="h-[30px] w-[30px]"
                    srcset=""
                  />{" "}
                  Gallery
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </React.Fragment>
  );
};

export default AdminNavbar;
