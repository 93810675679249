import React from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import bg from "../Assets/Clients/lgnBanner.png";

const Logout = () => {
  const navigate = useNavigate();

  const handleOk = () => {
    toast.success("hii");
    navigate("/");
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
      }}
      className="flex items-center justify-center min-h-screen bg-no-repeat bg-center"
    >
      <ToastContainer />

      <div className="bg-white p-8 rounded-lg text-center">
        <h1 className="text-2xl font-bold mb-4 text-primaryTextColor tracking-wider">
          Alright..!
        </h1>

        <p className="text-lg mb-6 text-secondaryTextColor tracking-wider font-semibold">
          Heading back to the starting point.
        </p>

        <div className="flex gap-6 items-center justify-center">
          <button
            className="bg-primaryColor hover:bg-primaryHoverColor tracking-wider text-white px-4 py-2 rounded-md transition-all duration-200 cursor-pointer"
            onClick={handleOk}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default Logout;
