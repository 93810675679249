import React from "react";
import Slider from "../Components/Home/Slider";
import ExperienceCard from "../Components/Home/HomeAboutUs";
import SectionOne from "../Components/Home/Section1";
import Services from "../Components/Home/Services";
import ServicesOffered from "../Components/Home/ServicesOffered";
import Achievement from "../Components/Home/Achievement";
import FeedBack from "../Components/Home/FeedBack";
import Blogs from "../Components/Home/Blogs";
import BottomSection from "../Components/Home/BottomSection";
import Products from "../Components/Products/Products";
import OurServices from "../Components/Services/OurServices";
import MAP from "../Components/Home/MAP";
import WhatsappComponent from "../Components/msgBox/WhatsappComponent";

const Home = () => {
  console.log(process.env.REACT_APP_BASE_URL);

  return (
    <div className="overflow-x-hidden">
      <Slider />
      <ExperienceCard />
      <Products />
      <SectionOne />
      <OurServices />
      <Services />
      <ServicesOffered />
      <MAP />
      <Achievement />
      <FeedBack />
      {/* <Blogs /> */}
      <BottomSection />
      <WhatsappComponent />
    </div>
  );
};

export default Home;
