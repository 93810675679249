import React from "react";
import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
import logo from "../Assets/onlyLogo.png";
import rmc from ".././Assets/Logos/Producers.png";
import logoText from "../Assets/newOnlyLogoText.png";
import CreateProductModal from "../Components/productModal/CreateProductModal";
import { useSelector } from "react-redux";
import UpdateProductModal from "../Components/productModal/UpdateProductModal";
import MyServiceModal from "../Components/serviceModal/MyServiceModal";
import EditService from "../Components/serviceModal/EditService";
import CreateCareers from "../Components/CareersModal/CreateCareers";
import UpdateCareers from "../Components/CareersModal/UpdateCareers";

const AdminAuthLayout = ({ children }) => {
  const modal = useSelector((state) => {
    return state.products.openModal;
  });
  const serviceModal = useSelector((state) => {
    return state.products.openServiceMoodal;
  });

  const editServiceModal = useSelector((state) => {
    return state.products.openEditServiceModal;
  });
  const updateModal = useSelector((state) => {
    return state.products.updateModal;
  });

  const careersModal = useSelector((state) => {
    return state.products.careersModal;
  });

  const editCareersModal = useSelector((state) => {
    return state.products.editCareersModal;
  });
  return (
    <React.Fragment>
      {modal ? <CreateProductModal /> : ""}
      {updateModal ? <UpdateProductModal /> : ""}
      {serviceModal ? <MyServiceModal /> : ""}
      {editServiceModal ? <EditService /> : ""}
      {careersModal ? <CreateCareers /> : ""}
      {editCareersModal ? <UpdateCareers /> : ""}
      <div className="flex h-screen bg-gray-100">
        <div className="w-[20%] bg-white px-4 border-r-2 lg:block hidden">
          <div className=" rounded-lg flex justify-center items-center w-full mb-4 py-2 gap-2">
            <img src={rmc} alt="Logo" className="w-40 h-auto my-1" />

            {/* <img src={logoText} alt="LogoText" className="w-40 h-auto" /> */}
          </div>

          <AdminSidebar />
        </div>

        <div className="flex flex-col flex-1 overflow-hidden">
          <header className="w-full bg-white h-16 fixed z-10 shadow-md">
            <AdminNavbar />
          </header>

          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#f5f6fa] pt-20 p-4">
            {children}
          </main>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminAuthLayout;
