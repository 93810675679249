import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import image2 from "../../Assets/HomeCarousel/image2.png";
import image3 from "../../Assets/HomeCarousel/image3.png";
import image4 from "../../Assets/HomeCarousel/image4.png";
import imag5 from "../../Assets/HomeCarousel/slider1.png";
import imag6 from "../../Assets/HomeCarousel/slider2.png";

import { IoArrowUndo } from "react-icons/io5";
import { IoArrowRedoSharp } from "react-icons/io5";

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [leftButtonHover, setLeftButtonHover] = useState(false);
  const [rightButtonHover, setRightButtonHover] = useState(false);

  const leftHandleHoverStart = (side) => {
    setLeftButtonHover(true);
  };

  const leftHandleHoverEnd = (side) => {
    setLeftButtonHover(false);
  };

  const rightHandleHoverStart = (side) => {
    setRightButtonHover(true);
  };

  const rightHandleHoverEnd = (side) => {
    setRightButtonHover(false);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  const images = [
    {
      src: image2,
      header: "The Smart Choice for Modern Construction",
      text: "Providing our services all over the INDIA.",
    },
    {
      src: image4,
      header: "Reliable RMC Equipment RentalsR",
      text: "Providing our services all over the INDIA.",
    },
    {
      src: image3,
      header: "Premium PQC & DLC Solutions",
      text: "Providing our services all over the INDIA.",
    },
    {
      src: imag5,
      header: "Reliable RMC Equipment RentalsR",
      text: "Providing our services all over the INDIA.",
    },
    {
      src: imag6,
      header: "Premium PQC & DLC Solutions",
      text: "Providing our services all over the INDIA.",
    },
  ];

  return (
    <React.Fragment>
      <div className="relative w-full md:h-[500px] h-[400px] overflow-hidden">
        <div
          className="flex transition-transform duration-300 ease-in-out transform"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <motion.div
              key={index}
              className={`w-full flex-shrink-0 relative ${
                index === currentIndex ? "opacity-100" : "opacity-0"
              }`}
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <img
                src={image.src}
                alt={`Slide ${index + 1}`}
                className="w-full md:h-[500px] h-[400px] object-cover"
              />
              {index === currentIndex && (
                <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center text-white text-center p-4">
                  <motion.h2
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.9 }}
                    className="lg:text-[60px] md:text-[50px] text-30px font-bold xl:w-[50%] lg:w-[65%] md:w-[85%] m-auto tracking-wider text-primaryColor leading-tight"
                  >
                    {image.header}
                  </motion.h2>

                  <motion.p
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.2 }}
                    className="md:w-[70%] w-[80%] m-auto md:mt-[-80px] mt-[-40px] md:text-[28px] text-[15px] tracking-wide font-medium"
                  >
                    {image.text}
                  </motion.p>
                </div>
              )}
            </motion.div>
          ))}
        </div>

        <motion.button
          onClick={prevSlide}
          onMouseEnter={leftHandleHoverStart}
          onMouseLeave={leftHandleHoverEnd}
          className="absolute top-1/2 left-0 transform -translate-y-1/2 focus:outline-none text-white hover:text-primaryColor font-bold cursor-pointer"
          whileHover={{ scale: 1.1 }}
        >
          <motion.div
            initial={{ x: 0 }}
            animate={{ x: leftButtonHover ? 0 : 10 }}
            transition={{ duration: 0.3 }}
          >
            <IoArrowUndo size={22} />
          </motion.div>
        </motion.button>

        <motion.button
          onClick={prevSlide}
          onMouseEnter={rightHandleHoverStart}
          onMouseLeave={rightHandleHoverEnd}
          className="absolute top-1/2 right-2 transform -translate-y-1/2 focus:outline-none text-white hover:text-primaryColor font-bold cursor-pointer"
          whileHover={{ scale: 1.1 }}
        >
          <motion.div
            initial={{ x: 0 }}
            animate={{ x: rightButtonHover ? 10 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <IoArrowRedoSharp size={22} />
          </motion.div>
        </motion.button>

        {/* <button
                    onClick={prevSlide}
                    className="absolute top-1/2 left-2 transform -translate-y-1/2 focus:outline-none text-white animate-pulse">
                    <RiArrowLeftDoubleFill size={48} />
                </button> */}

        {/* <button
                    onClick={nextSlide}
                    className="absolute top-1/2 right-2 transform -translate-y-1/2 focus:outline-none text-white animate-pulse">
                    <RiArrowRightDoubleFill size={48} />
                </button> */}

        <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
          {images.map((_, index) => (
            <button
              key={index}
              onClick={() => goToSlide(index)}
              className={`h-2 w-2  rounded-full focus:outline-none ${
                currentIndex === index ? "bg-primaryColor" : "bg-gray-200"
              }`}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Slider;
